import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from '../../../firebaseConfig';
import { FaPlus } from 'react-icons/fa';
import ServiceCard from './ServiceCard';
import AddServiceForm from './AddServiceForm';
import Modal from '../../Shared/Modal';
import ServiceDetailsModal from '../../Explore Services/ServiceDetailsModal';
import styles from './ProfileServices.module.css';
import { toast } from 'react-toastify';

const ProfileServices = ({ isOwner, userId, onHire = () => {}, setError }) => {
  // Estados principales
  const [servicesList, setServicesList] = useState([]);
  const [loadingServicesList, setLoadingServicesList] = useState(true);
  const [selectedService, setSelectedService] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [showServiceForm, setShowServiceForm] = useState(false);
  const [confirmServiceDelete, setConfirmServiceDelete] = useState(null);
  const [isDeletingServiceId, setIsDeletingServiceId] = useState(null);

  // Cargar servicios
  useEffect(() => {
    const servicesRef = collection(db, 'users', userId, 'Services');
    const unsubscribe = onSnapshot(servicesRef, (snapshot) => {
      const servicesData = snapshot.docs
        .map((doc) => ({
          serviceID: doc.id,
          ...doc.data(),
          prices: doc.data().prices || [],
        }))
        .sort((a, b) => b.createdAt - a.createdAt);
      
      setServicesList(servicesData);
      setLoadingServicesList(false);
    }, (error) => {
      console.error('Error fetching services:', error);
      setError('Error al obtener los servicios.');
      setLoadingServicesList(false);
    });

    return () => unsubscribe();
  }, [userId, setError]);

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setIsDetailsModalOpen(true);
  };

  const handleDeleteConfirm = (service) => {
    setConfirmServiceDelete(service);
  };

  const handleDeleteService = async (serviceId, imagePath) => {
    setIsDeletingServiceId(serviceId);
    try {
      // Primero eliminamos el documento
      const serviceRef = doc(db, 'users', userId, 'Services', serviceId);
      await deleteDoc(serviceRef);
      
      // Luego eliminamos la imagen
      const imageRef = ref(storage, imagePath);
      await deleteObject(imageRef);
      
      setConfirmServiceDelete(null);
      toast.success('Servicio eliminado exitosamente!');
    } catch (error) {
      console.error('Error al eliminar el servicio:', error);
      toast.error('Error al eliminar el servicio.');
    } finally {
      setIsDeletingServiceId(null);
    }
  };

  const handleAddSuccess = () => {
    setShowServiceForm(false);
    toast.success('Servicio añadido exitosamente!');
  };

  return (
    <div className={styles.servicesContainer}>
      <h2 className={styles.servicesTitle}>Mis Servicios</h2>
      {isOwner && (
        <button
          className={styles.addServiceButton}
          onClick={() => setShowServiceForm(true)}
          aria-label="Añadir nuevo servicio"
        >
          <FaPlus className={styles.buttonIcon} />
          Añadir Nuevo Servicio
        </button>
      )}

      <div className={styles.servicesGrid}>
        {loadingServicesList ? (
          <p className={styles.loadingText}>Cargando servicios...</p>
        ) : servicesList.length === 0 ? (
          <p className={styles.noServicesText}>No hay servicios disponibles.</p>
        ) : (
          servicesList.map((service) => (
            <ServiceCard
              key={service.serviceID}
              service={service}
              isOwner={isOwner}
              onServiceClick={handleServiceClick}
              onHire={onHire}
              onDeleteConfirm={handleDeleteConfirm}
              userId={userId}
              isDeleting={isDeletingServiceId === service.serviceID}
            />
          ))
        )}
      </div>

      <Modal isOpen={showServiceForm} onClose={() => setShowServiceForm(false)}>
        <AddServiceForm
          userId={userId}
          onSuccess={handleAddSuccess}
          onClose={() => setShowServiceForm(false)}
        />
      </Modal>

      <Modal isOpen={isDetailsModalOpen} onClose={() => setIsDetailsModalOpen(false)}>
        {selectedService && (
          <ServiceDetailsModal
            service={selectedService}
            closeModal={() => setIsDetailsModalOpen(false)}
          />
        )}
      </Modal>

      <Modal isOpen={!!confirmServiceDelete} onClose={() => setConfirmServiceDelete(null)}>
        {confirmServiceDelete && (
          <div className={styles.confirmModalContent}>
            <h3>Confirmar Eliminación</h3>
            <p>¿Estás seguro de que deseas eliminar este servicio?</p>
            <div className={styles.confirmButtons}>
              <button
                className={styles.confirmButton}
                onClick={() => handleDeleteService(
                  confirmServiceDelete.serviceID, 
                  confirmServiceDelete.imagePath
                )}
                disabled={isDeletingServiceId === confirmServiceDelete.serviceID}
              >
                {isDeletingServiceId === confirmServiceDelete.serviceID 
                  ? 'Eliminando...' 
                  : 'Sí, Eliminar'}
              </button>
              <button
                className={styles.cancelButton}
                onClick={() => setConfirmServiceDelete(null)}
              >
                Cancelar
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

ProfileServices.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  onHire: PropTypes.func,
  setError: PropTypes.func.isRequired,
};

export default ProfileServices;