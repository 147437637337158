import React, { useState, useEffect, useContext } from 'react';
import styles from './Profile.module.css';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebaseConfig';
import ProfileBio from './ProfileBio';
import ProfileServices from './services/ProfileServices';
import ProfilePortfolio from './ProfilePortfolio';
import ProfilePicture from './ProfilePicture';
import ProfileStats from './ProfileStats';
import ProfileSocial from './ProfileSocial';
import UserContext from '../../context/UserContext';
import { useParams } from 'react-router-dom';
import Modal from '../Shared/Modal';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { toast } from 'react-toastify';

const ErrorMessage = ({ message }) => (
  <div className={styles.errorContainer}>
    <p>{message}</p>
    <button onClick={() => window.location.reload()} className={styles.refreshButton}>
      Intentar nuevamente
    </button>
  </div>
);

const TabContent = ({ isActive, children }) => (
  <div className={`${styles.tabPanel} ${isActive ? styles.tabPanelActive : ''}`}>
    {children}
  </div>
);

const Profile = () => {
  const { user, setUser } = useContext(UserContext);
  const [profileError, setProfileError] = useState(false);
  const [activeTab, setActiveTab] = useState('services');
  const [errorMessage, setErrorMessage] = useState('');
  const [profileData, setProfileData] = useState(null);
  const { userId } = useParams();

  const [isEditBannerOpen, setIsEditBannerOpen] = useState(false);
  const [newBanner, setNewBanner] = useState(null);
  const [uploadingBanner, setUploadingBanner] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) {
        setProfileError(true);
        setErrorMessage('ID de usuario no válido');
        return;
      }

      try {
        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = { 
            id: userSnap.id, 
            ...userSnap.data(),
            socialLinks: userSnap.data().socialLinks || {
              instagram: '',
              twitter: '',
              github: '',
              linkedin: '',
              website: ''
            }
          };
          setProfileData(userData);
          setUser(userData);
        } else {
          setProfileError(true);
          setErrorMessage('Perfil no encontrado');
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setProfileError(true);
        setErrorMessage('Error al cargar el perfil');
      }
    };

    fetchUserData();
  }, [userId, setUser]);

  const handleBannerUpload = async () => {
    if (!newBanner) return;
    setUploadingBanner(true);
    try {
      const storageRef = ref(storage, `banners/${profileData.id}/${newBanner.name}`);
      const uploadTask = uploadBytesResumable(storageRef, newBanner);
      
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Opcional: Implementar una barra de progreso
        }, 
        (error) => {
          console.error('Error subiendo el banner:', error);
          toast.error('Error al subir el banner');
          setUploadingBanner(false);
        }, 
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await updateDoc(doc(db, 'users', profileData.id), {
            bannerURL: downloadURL
          });
          setProfileData(prev => ({ ...prev, bannerURL: downloadURL }));
          setIsEditBannerOpen(false);
          setNewBanner(null);
          setUploadingBanner(false);
          toast.success('Banner actualizado exitosamente');
        }
      );
    } catch (error) {
      console.error('Error al actualizar el banner:', error);
      toast.error('Error al actualizar el banner');
      setUploadingBanner(false);
    }
  };

  if (profileError) {
    return <ErrorMessage message={errorMessage} />;
  }

  if (!profileData) {
    return <div className={styles.loading}>Cargando perfil...</div>;
  }

  const isOwner = user?.uid === userId;

  return (
    <div className={styles.profileContainer}>
      <div className={styles.profileContent}>
        <div className={styles.banner}>
          {profileData.bannerURL ? (
            <img src={profileData.bannerURL} alt="Banner de usuario" className={styles.bannerImage} />
          ) : (
            <div className={styles.placeholderBanner}>Sin banner</div>
          )}
          {isOwner && (
            <button 
              className={styles.editBannerButton} 
              onClick={() => setIsEditBannerOpen(true)}
            >
              Editar
            </button>
          )}
        </div>
        
        <div className={styles.profileBox}>
          <div className={styles.profileHeader}>
            <div className={styles.profilePictureContainer}>
              <ProfilePicture
                photoURL={profileData.photoURL}
                isOwner={isOwner}
                setPhotoURL={(url) => setProfileData(prev => ({ ...prev, photoURL: url }))}
                setError={setProfileError}
              />
            </div>
            
            <div className={styles.userInfo}>
              <div className={styles.nameAndMeta}>
                <h1 className={styles.userName}>{profileData.name}</h1>
                <div className={styles.userMetaInfo}>
                  <p className={styles.userHandle}>@{profileData.username}</p>
                  <span className={styles.roleBadge}>{profileData.role}</span>
                </div>
                <div className={styles.socialLinksWrapper}>
                  <ProfileSocial 
                    links={profileData.socialLinks} 
                    userId={profileData.id}
                    isOwner={isOwner} 
                  />
                </div>
              </div>
              
              <div className={styles.statsContainer}>
                <ProfileStats userId={profileData.id} />
              </div>
            </div>
          </div>

          <div className={styles.bioWrapper}>
            <ProfileBio 
              user={profileData} 
              isOwner={isOwner}
              updateBio={(newBio) => setProfileData(prev => ({ ...prev, bio: newBio }))} 
            />
          </div>
        </div>

        <div className={styles.contentTabs}>
          <div className={styles.tabsHeader}>
            <button 
              className={`${styles.tab} ${activeTab === 'services' ? styles.activeTab : ''}`}
              onClick={() => setActiveTab('services')}
            >
              Mis Servicios
            </button>
            <button 
              className={`${styles.tab} ${activeTab === 'portfolio' ? styles.activeTab : ''}`}
              onClick={() => setActiveTab('portfolio')}
            >
              Portafolio
            </button>
          </div>
          
          <div className={styles.tabContent}>
            <TabContent isActive={activeTab === 'services'}>
              {profileData.id && (
                <ProfileServices
                  userId={profileData.id}
                  isOwner={isOwner}
                  setError={setProfileError}
                />
              )}
            </TabContent>
            <TabContent isActive={activeTab === 'portfolio'}>
              {profileData.id && (
                <ProfilePortfolio
                  userId={profileData.id}
                  isOwner={isOwner}
                  setError={setProfileError}
                />
              )}
            </TabContent>
          </div>
        </div>
      </div>

      <Modal isOpen={isEditBannerOpen} onClose={() => setIsEditBannerOpen(false)}>
        <div className={styles.modalContent}>
          <h2>Editar Banner</h2>
          <input 
            type="file" 
            accept="image/*" 
            onChange={(e) => setNewBanner(e.target.files[0])} 
            className={styles.fileInput}
          />
          {newBanner && (
            <img 
              src={URL.createObjectURL(newBanner)} 
              alt="Vista previa del nuevo banner" 
              className={styles.previewImage} 
            />
          )}
          <div className={styles.modalButtons}>
            <button 
              className={styles.saveButton} 
              onClick={handleBannerUpload}
              disabled={!newBanner || uploadingBanner}
            >
              {uploadingBanner ? 'Subiendo...' : 'Guardar'}
            </button>
            <button 
              className={styles.cancelButton} 
              onClick={() => { setIsEditBannerOpen(false); setNewBanner(null); }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;