import React, { useContext, useState } from 'react';
import UserContext from '../context/UserContext';
import { db } from '../firebaseConfig';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  serverTimestamp, 
  increment,
  doc,
  writeBatch,
} from 'firebase/firestore'; 
import '../Styles/UserDashboard.css'; 
import TransactionHistory from './TransactionHistory';
import { FaWallet, FaClock, FaArrowDown } from 'react-icons/fa';
import { toast } from 'react-toastify';

const UserDashboard = () => {
  const { user, loading, error } = useContext(UserContext);
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const handleWithdrawalRequest = async () => {
    if (!user || user.balance <= 0) return;
    
    setIsWithdrawing(true);

    try {
      const batch = writeBatch(db);
      const userRef = doc(db, 'users', user.uid);
      const transactionsRef = collection(db, `users/${user.uid}/transactions`);
      const withdrawalAmount = -user.balance;

      // Crear nuevo ID para la transacción
      const newTransactionId = doc(collection(db, 'transactions')).id;

      // 1. Crear la solicitud en withdrawalRequests
      const withdrawalRequestRef = doc(db, 'withdrawalRequests', newTransactionId);
      batch.set(withdrawalRequestRef, {
        userId: user.uid,
        username: user.displayName || 'Usuario',
        userPhotoURL: user.photoURL || null,
        amount: Math.abs(withdrawalAmount),
        status: 'pending',
        timestamp: serverTimestamp(),
        email: user.email
      });

      // 2. Crear la transacción en el historial del usuario
      const userTransactionRef = doc(transactionsRef, newTransactionId);
      batch.set(userTransactionRef, {
        type: 'withdrawal',
        amount: withdrawalAmount,
        status: 'pending',
        timestamp: serverTimestamp(),
      });

      // 3. Crear la transacción global
      const globalTransactionRef = doc(db, 'transactions', newTransactionId);
      batch.set(globalTransactionRef, {
        userId: user.uid,
        username: user.displayName || 'Usuario',
        type: 'withdrawal',
        amount: withdrawalAmount,
        status: 'pending',
        timestamp: serverTimestamp(),
      });

      // 4. Actualizar balances del usuario
      batch.update(userRef, {
        balance: 0,
        pendingBalance: increment(Math.abs(withdrawalAmount))
      });

      // Ejecutar todas las operaciones
      await batch.commit();

      toast.success('Solicitud de retiro procesada exitosamente');
    } catch (err) {
      console.error('Error al procesar el retiro:', err);
      toast.error('Error al procesar el retiro. Por favor, intenta de nuevo.');
    } finally {
      setIsWithdrawing(false);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2,
    }).format(amount);
  };

  if (loading) {
    return (
      <div className="dashboard-wrapper">
        <div className={`user-dashboard ${user?.darkMode ? 'dark-mode' : ''}`}>
          <div className="loading-spinner">
            <div className="spinner"></div>
            <p>Cargando dashboard...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard-wrapper">
        <div className={`user-dashboard ${user?.darkMode ? 'dark-mode' : ''}`}>
          <div className="error-message" role="alert">
            <p>{error}</p>
            <button onClick={() => window.location.reload()}>Reintentar</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard-wrapper">
      <div className={`user-dashboard ${user?.darkMode ? 'dark-mode' : ''}`}>
        <h1>Dashboard</h1>
        
        <div className="dashboard-header">
          <div className="balance-cards">
            <div className="balance-card">
              <div className="balance-icon">
                <FaWallet />
              </div>
              <div className="balance-info">
                <h3>Saldo Disponible</h3>
                <p>{user ? formatCurrency(user.balance) : 'Cargando...'}</p>
              </div>
              {user?.balance > 0 && (
                <button 
                  className="withdrawal-button"
                  onClick={handleWithdrawalRequest} 
                  disabled={isWithdrawing || user.balance <= 0}
                >
                  <FaArrowDown className="button-icon" />
                  {isWithdrawing ? 'Procesando...' : 'Retirar'}
                </button>
              )}
            </div>
            
            <div className="balance-card">
              <div className="balance-icon">
                <FaClock />
              </div>
              <div className="balance-info">
                <h3>Saldo Pendiente</h3>
                <p>{user ? formatCurrency(user.pendingBalance || 0) : 'Cargando...'}</p>
              </div>
            </div>
          </div>
        </div>
        
        <TransactionHistory 
          userId={user?.uid} 
          darkMode={user?.darkMode}
        />
      </div>
    </div>
  );
};

export default UserDashboard;