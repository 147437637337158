// src/components/Pending.js

import React from 'react';
import './PaymentStatus.css';

const Pending = () => {
  return (
    <div className="payment-status-container">
      <h1>Pago Pendiente</h1>
      <p>Tu pago está siendo procesado. Recibirás una notificación una vez que se complete.</p>
      <div className="loader"></div>
    </div>
  );
};

export default Pending;
