import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import styles from './ProfileStats.module.css';
import PropTypes from 'prop-types';

const ProfileStats = ({ userId }) => {
    const [stats, setStats] = useState({
        followers: 0,
        following: 0,
        services: 0,
        portfolio: 0
    });

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const userDoc = await getDoc(doc(db, 'users', userId));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setStats({
                        followers: userData.followers?.length || 0,
                        following: userData.following?.length || 0,
                        services: userData.servicesCount || 0,
                        portfolio: userData.portfolioCount || 0
                    });
                }
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };

        if (userId) {
            fetchStats();
        }
    }, [userId]);

    return (
        <div className={styles.statsContainer}>
            <div className={styles.statItem}>
                <span className={styles.statNumber}>{stats.followers}</span>
                <span className={styles.statLabel}>Seguidores</span>
            </div>
            <div className={styles.statItem}>
                <span className={styles.statNumber}>{stats.following}</span>
                <span className={styles.statLabel}>Siguiendo</span>
            </div>
            <div className={styles.statItem}>
                <span className={styles.statNumber}>{stats.services}</span>
                <span className={styles.statLabel}>Servicios</span>
            </div>
            <div className={styles.statItem}>
                <span className={styles.statNumber}>{stats.portfolio}</span>
                <span className={styles.statLabel}>Portafolio</span>
            </div>
        </div>
    );
};

ProfileStats.propTypes = {
    userId: PropTypes.string.isRequired
};

export default ProfileStats;