// src/components/Layout.jsx
import React, { useState } from 'react';
import Header from './Header/Header';
import AddBalance from './addBalance';
import '../Styles/Layout.css';

const Layout = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null);

  // Función para abrir un modal y cerrar otros modales
  const openModal = (modalName) => {
    setActiveModal(modalName);
  };

  // Función para cerrar el modal activo
  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <div className="layout">
      <Header openModal={openModal} />
      <main aria-hidden={activeModal !== null}>
        <div className="container">
          {children}
        </div>
      </main>

      {/* Modal de Añadir Saldo */}
      {activeModal === 'addBalance' && <AddBalance onClose={closeModal} />}
    </div>
  );
};

export default Layout;
