import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { db, storage } from '../../../firebaseConfig';
import { collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { FaTimes, FaPlus } from 'react-icons/fa';
import ImageCropperModal from '../ImageCropperModal';
import styles from './AddServiceForm.module.css';
import { toast } from 'react-toastify';

const AddServiceForm = ({ userId, onSuccess, onClose }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    terms: '',
    specifications: '',
    prices: [{ price: '', explanation: '' }],
    category: 'Sin categoría' // Añadido campo de categoría
  });
  
  const [imageSrc, setImageSrc] = useState(null);
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePriceChange = (index, field, value) => {
    const newPrices = [...formData.prices];
    newPrices[index] = {
      ...newPrices[index],
      [field]: field === 'price' ? value.replace(/[^0-9]/g, '') : value // Solo permite números en el campo price
    };
    handleInputChange('prices', newPrices);
  };

  const addPriceOption = () => {
    if (formData.prices.length < 5) {
      setFormData(prev => ({
        ...prev,
        prices: [...prev.prices, { price: '', explanation: '' }]
      }));
    }
  };

  const removePriceOption = (index) => {
    if (formData.prices.length > 1) {
      setFormData(prev => ({
        ...prev,
        prices: prev.prices.filter((_, i) => i !== index)
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('El archivo seleccionado no es una imagen.');
      toast.error('El archivo seleccionado no es una imagen.');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
      setIsCropperOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCroppedImage = (croppedImageBlob) => {
    const uniqueFileName = `cropped_${uuidv4()}.png`;
    const croppedFile = new File([croppedImageBlob], uniqueFileName, {
      type: 'image/png',
    });
    setCroppedImageFile(croppedFile);
    setCroppedImageUrl(URL.createObjectURL(croppedImageBlob));
    setIsCropperOpen(false);
  };

  const validateForm = () => {
    const { title, description, terms, specifications, prices } = formData;
    
    if (!title.trim() || !description.trim() || !terms.trim() || 
        !specifications.trim() || !croppedImageFile) {
      setError('Todos los campos obligatorios deben estar completos.');
      return false;
    }

    const validPrices = prices.filter(price => 
      price.price && price.explanation && Number(price.price) > 0
    );

    if (validPrices.length === 0) {
      setError('Debe ingresar al menos una opción de precio válida mayor a 0 con su explicación.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setIsSubmitting(true);
    setError('');

    try {
      const uniqueImageName = `${uuidv4()}_${croppedImageFile.name}`;
      const imagePath = `services/${userId}/${uniqueImageName}`;
      const serviceImageRef = ref(storage, imagePath);
      
      await uploadBytes(serviceImageRef, croppedImageFile);
      const imageUrl = await getDownloadURL(serviceImageRef);

      const validPrices = formData.prices
        .filter(price => price.price && Number(price.price) > 0)
        .map(price => ({
          price: price.price.toString(),
          explanation: price.explanation.trim()
        }));

      const newService = {
        illustratorId: userId,
        ...formData,
        prices: validPrices,
        price: validPrices[0].price, // Precio base
        currency: 'ARS',
        imageUrl,
        imagePath,
        createdAt: new Date(),
        isDisabled: false,
      };

      const servicesRef = collection(db, 'users', userId, 'Services');
      const addedService = await addDoc(servicesRef, newService);
      await updateDoc(doc(servicesRef, addedService.id), { 
        serviceID: addedService.id 
      });

      onSuccess();
    } catch (error) {
      console.error('Error al añadir el servicio:', error);
      setError('Error al añadir el servicio. Inténtalo de nuevo.');
      toast.error('Error al añadir el servicio.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.formContainer}>
      <button
        className={styles.closeButton}
        onClick={onClose}
        aria-label="Cerrar formulario"
      >
        <FaTimes />
      </button>

      <h3 className={styles.formTitle}>Añadir Nuevo Servicio</h3>

      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="text"
          value={formData.title}
          onChange={(e) => handleInputChange('title', e.target.value)}
          placeholder="Título del Servicio"
          className={styles.input}
          maxLength={50} // Limitamos el título a 50 caracteres
        />

        <textarea
          value={formData.description}
          onChange={(e) => handleInputChange('description', e.target.value)}
          placeholder="Descripción del Servicio"
          className={styles.textarea}
          maxLength={500} // Limitamos la descripción
        />

        <textarea
          value={formData.terms}
          onChange={(e) => handleInputChange('terms', e.target.value)}
          placeholder="Términos del Servicio"
          className={styles.textarea}
        />

        <textarea
          value={formData.specifications}
          onChange={(e) => handleInputChange('specifications', e.target.value)}
          placeholder="Especificaciones Extensas del Servicio"
          className={styles.textarea}
        />

        <select
          value={formData.category}
          onChange={(e) => handleInputChange('category', e.target.value)}
          className={styles.input}
        >
          <option value="Sin categoría">Sin categoría</option>
          <option value="Ilustración">Ilustración</option>
          <option value="Diseño Gráfico">Diseño Gráfico</option>
          <option value="Animación">Animación</option>
          {/* Añade más categorías según necesites */}
        </select>

        <div className={styles.pricesContainer}>
          <h4 className={styles.pricesTitle}>Opciones de Precio</h4>
          {formData.prices.map((priceOption, index) => (
            <div key={index} className={styles.priceInputGroup}>
              <div className={styles.priceInputs}>
                <input
                  type="text" // Cambiado a text para mejor control
                  value={priceOption.price}
                  onChange={(e) => handlePriceChange(index, 'price', e.target.value)}
                  placeholder="Precio (ARS$)"
                  className={styles.priceInput}
                />
                <input
                  type="text"
                  value={priceOption.explanation}
                  onChange={(e) => handlePriceChange(index, 'explanation', e.target.value)}
                  placeholder="Explicación del precio"
                  className={styles.priceExplanation}
                />
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => removePriceOption(index)}
                    className={styles.removePriceButton}
                    aria-label="Eliminar opción de precio"
                  >
                    <FaTimes />
                  </button>
                )}
              </div>
            </div>
          ))}
          {formData.prices.length < 5 && (
            <button
              type="button"
              onClick={addPriceOption}
              className={styles.addPriceButton}
            >
              <FaPlus /> Añadir opción de precio
            </button>
          )}
        </div>

        <div className={styles.imageUploadSection}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className={styles.fileInput}
          />
          {croppedImageUrl && (
            <div className={styles.imagePreview}>
              <img 
                src={croppedImageUrl} 
                alt="Vista previa" 
                className={styles.previewImage}
              />
            </div>
          )}
        </div>

        {error && <p className={styles.errorMessage}>{error}</p>}

        <button
          type="submit"
          className={styles.submitButton}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Añadiendo...' : 'Añadir Servicio'}
        </button>
      </form>

      {isCropperOpen && (
        <ImageCropperModal
          isOpen={isCropperOpen}
          onClose={() => setIsCropperOpen(false)}
          onSave={handleCroppedImage}
          imageSrc={imageSrc}
          aspect={16 / 9}
        />
      )}
    </div>
  );
};

AddServiceForm.propTypes = {
  userId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddServiceForm;