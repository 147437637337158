// ImageCropperModal.jsx
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import styles from './ImageCropperModal.module.css';
import Modal from '../Shared/Modal';
import { FaTimes, FaCheck } from 'react-icons/fa';

const ImageCropperModal = ({ isOpen, onClose, onSave, imageSrc, aspect }) => {
    const [cropper, setCropper] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const handleClose = useCallback(() => {
        if (hasChanges) {
            if (window.confirm('¿Estás seguro que deseas cerrar? Los cambios no se guardarán.')) {
                onClose();
            }
        } else {
            onClose();
        }
    }, [hasChanges, onClose]);

    const handleCrop = () => {
        setHasChanges(true);
    };

    const handleSave = async () => {
        if (!cropper) return;
        
        setLoading(true);
        try {
            const canvas = cropper.getCroppedCanvas({
                maxWidth: 1920,
                maxHeight: 1080,
                fillColor: '#fff',
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high',
            });

            canvas.toBlob(
                (blob) => {
                    if (blob) {
                        onSave(blob);
                        setHasChanges(false);
                    } else {
                        throw new Error('No se pudo crear el blob de la imagen');
                    }
                },
                'image/jpeg',
                0.8
            );
        } catch (error) {
            console.error('Error al recortar la imagen:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <div 
                className={styles.cropperContainer}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.cropperHeader}>
                    <h2 className={styles.title}>Recortar Imagen</h2>
                    <button 
                        onClick={handleClose}
                        className={styles.closeButton}
                        aria-label="Cerrar"
                    >
                        <FaTimes />
                    </button>
                </div>
                
                <div className={styles.cropperWrapper}>
                    <Cropper
                        src={imageSrc}
                        style={{ height: 400, width: '100%' }}
                        initialAspectRatio={aspect}
                        aspectRatio={aspect}
                        guides={true}
                        cropBoxResizable={false}
                        cropBoxMovable={true}
                        dragMode="move"
                        responsive={true}
                        background={false}
                        onInitialized={(instance) => setCropper(instance)}
                        crop={handleCrop}
                        viewMode={1}
                    />
                </div>

                <div className={styles.buttonContainer}>
                    <button 
                        onClick={handleSave}
                        className={styles.saveButton}
                        disabled={loading || !hasChanges}
                    >
                        <FaCheck />
                        {loading ? 'Guardando...' : 'Guardar'}
                    </button>
                    <button 
                        onClick={handleClose}
                        className={styles.cancelButton}
                    >
                        <FaTimes />
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

ImageCropperModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    imageSrc: PropTypes.string,
    aspect: PropTypes.number
};

export default ImageCropperModal;