import React from 'react';
import PropTypes from 'prop-types';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { FaToggleOn, FaToggleOff, FaTrash } from 'react-icons/fa';
import styles from './ServiceCard.module.css';
import { toast } from 'react-toastify';

const ServiceCard = ({
  service,
  isOwner,
  onServiceClick,
  onHire,
  onDeleteConfirm,
  userId,
  isDeleting
}) => {
  const handleToggleStatus = async (e) => {
    e.stopPropagation();
    try {
      const serviceRef = doc(db, 'users', userId, 'Services', service.serviceID);
      await updateDoc(serviceRef, { isDisabled: !service.isDisabled });
      toast.success('Estado del servicio actualizado!');
    } catch (error) {
      console.error('Error al cambiar el estado del servicio:', error);
      toast.error('Error al cambiar el estado del servicio.');
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDeleteConfirm(service);
  };

  const handleHire = (e) => {
    e.stopPropagation();
    onHire(userId, service.serviceID);
  };

  // Función para limitar texto
  const truncateText = (text, limit) => {
    if (!text) return '';
    if (text.length <= limit) return text;
    return text.substring(0, limit) + '...';
  };

  // Obtener el precio mínimo
  const getMinPrice = () => {
    if (!service.prices || service.prices.length === 0) return 0;
    
    const validPrices = service.prices
      .filter(price => price.price && Number(price.price) > 0)
      .map(price => Number(price.price));
      
    return validPrices.length > 0 ? validPrices[0] : 0;
  };

  const hasValidPrice = getMinPrice() > 0;

  return (
    <div
      className={styles.serviceCard}
      onClick={() => onServiceClick(service)}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => e.key === 'Enter' && onServiceClick(service)}
    >
      <div className={styles.serviceImageContainer}>
        <img
          src={service.imageUrl}
          alt={service.title}
          className={styles.serviceImage}
        />
        {service.isDisabled && (
          <div className={styles.serviceDisabledOverlay}>
            <span>Deshabilitado</span>
          </div>
        )}
      </div>

      <div className={styles.serviceInfo}>
        <h3 className={styles.serviceTitle}>
          {truncateText(service.title, 50)}
        </h3>
        <p className={styles.serviceDescription}>
          {truncateText(service.description, 100)}
        </p>
        <div className={styles.categoryTag}>
          {service.category || 'Sin categoría'}
        </div>
        {hasValidPrice && (
          <p className={styles.servicePrice}>
            Desde ARS${getMinPrice().toLocaleString()}
          </p>
        )}

        {!isOwner && !service.isDisabled && (
          <button
            className={styles.hireButton}
            onClick={handleHire}
            aria-label={`Contratar servicio ${service.title}`}
          >
            Contratar
          </button>
        )}
      </div>

      {isOwner && (
        <div className={styles.serviceActions} onClick={e => e.stopPropagation()}>
          <button
            className={`${styles.actionButton} ${styles.toggleButton}`}
            onClick={handleToggleStatus}
            aria-label={service.isDisabled ? 
              `Habilitar servicio ${service.title}` : 
              `Deshabilitar servicio ${service.title}`}
          >
            {service.isDisabled ? (
              <>
                <FaToggleOff className={styles.buttonIcon} />
                Habilitar
              </>
            ) : (
              <>
                <FaToggleOn className={styles.buttonIcon} />
                Deshabilitar
              </>
            )}
          </button>
          <button
            className={`${styles.actionButton} ${styles.deleteButton}`}
            onClick={handleDelete}
            disabled={isDeleting}
            aria-label={`Eliminar servicio ${service.title}`}
          >
            <FaTrash className={styles.buttonIcon} />
            Eliminar
          </button>
        </div>
      )}
    </div>
  );
};

ServiceCard.propTypes = {
  service: PropTypes.shape({
    serviceID: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.string,
        explanation: PropTypes.string
      })
    ).isRequired,
    category: PropTypes.string,
    isDisabled: PropTypes.bool
  }).isRequired,
  isOwner: PropTypes.bool.isRequired,
  onServiceClick: PropTypes.func.isRequired,
  onHire: PropTypes.func.isRequired,
  onDeleteConfirm: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  isDeleting: PropTypes.bool
};

export default ServiceCard;