// src/components/Profile/ProfilePortfolio.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { db, storage } from '../../firebaseConfig';
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import styles from './ProfilePortfolio.module.css';
import { FaTrash, FaPlus, FaTimes } from 'react-icons/fa';
import Modal from '../Shared/Modal';
import { toast } from 'react-toastify';
import ImageCropperModal from './ImageCropperModal';

const ProfilePortfolio = ({ isOwner, userId, setError }) => {
  const [portfolio, setPortfolio] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [mediaError, setMediaError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  // Estados para eliminar imágenes
  const [confirmDeleteImage, setConfirmDeleteImage] = useState(null);
  const [isDeletingImageId, setIsDeletingImageId] = useState(null);

  // Cargar el portafolio en tiempo real
  useEffect(() => {
    const userDocRef = doc(db, 'users', userId);
    const unsubscribe = onSnapshot(
      userDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setPortfolio(userData.portfolio || []);
        }
      },
      (error) => {
        console.error('Error fetching portfolio:', error);
        setError('Error al obtener el portafolio.');
        toast.error('Error al obtener el portafolio.');
      }
    );

    return () => unsubscribe();
  }, [userId, setError]);

  // Alternar la visibilidad del modal de subida
  const toggleUploadModal = () => {
    setShowUploadModal(!showUploadModal);
    setImageFile(null);
    setImagePreview(null);
    setMediaError('');
  };

  // Manejar el cambio de archivo (solo imágenes)
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validTypes.includes(file.type)) {
        setMediaError(
          'Tipo de archivo no soportado. Puedes subir imágenes (JPEG, PNG, GIF).'
        );
        toast.error(
          'Tipo de archivo no soportado. Puedes subir imágenes (JPEG, PNG, GIF).'
        );
        return;
      }
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      setMediaError('');
    }
  };

  // Manejar la subida de imágenes
  const handleUpload = async () => {
    if (!imageFile) {
      setMediaError('Por favor, selecciona una imagen para subir.');
      toast.error('Por favor, selecciona una imagen para subir.');
      return;
    }

    setUploading(true);
    setMediaError('');

    try {
      const uniqueImageName = `${uuidv4()}_${imageFile.name}`;
      const imageRef = ref(storage, `portfolio/${userId}/${uniqueImageName}`);
      await uploadBytes(imageRef, imageFile);
      const downloadURL = await getDownloadURL(imageRef);

      const newImage = {
        storagePath: imageRef.fullPath,
        url: downloadURL,
      };

      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, {
        portfolio: arrayUnion(newImage),
      });

      toast.success('Imagen subida exitosamente!');
      toggleUploadModal();
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      setMediaError('Error al subir la imagen. Inténtalo de nuevo.');
      toast.error('Error al subir la imagen. Inténtalo de nuevo.');
    } finally {
      setUploading(false);
    }
  };

  // Manejar la eliminación de imágenes
  const handleDeleteImage = async (image) => {
    setIsDeletingImageId(image.storagePath);
    try {
      const imageRef = ref(storage, image.storagePath);
      await deleteObject(imageRef);

      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, {
        portfolio: arrayRemove(image),
      });

      toast.success('Imagen eliminada exitosamente!');
      setConfirmDeleteImage(null);
    } catch (error) {
      console.error('Error al eliminar la imagen:', error);
      setError('Error al eliminar la imagen. Inténtalo de nuevo.');
      toast.error('Error al eliminar la imagen. Inténtalo de nuevo.');
    } finally {
      setIsDeletingImageId(null);
    }
  };

  return (
    <div className={styles.portfolioContainer}>
      <h2 className={styles.portfolioTitle}>Portafolio</h2>
      {isOwner && (
        <button
          className={styles.addMediaButton}
          onClick={toggleUploadModal}
          aria-label="Añadir nueva imagen al portafolio"
        >
          <FaPlus className={styles.buttonIcon} />
          Añadir Imagen
        </button>
      )}
      <div className={styles.mediaGrid}>
        {portfolio.length > 0 ? (
          portfolio.map((image, index) => (
            <div key={index} className={styles.mediaItem}>
              <img
                src={image.url}
                alt={`Portafolio ${index + 1}`}
                className={styles.mediaContent}
                loading="lazy"
                onClick={() => setSelectedImage(image)}
              />
              {isOwner && (
                <button
                  className={styles.deleteButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmDeleteImage(image);
                  }}
                  disabled={isDeletingImageId === image.storagePath}
                  aria-label={`Eliminar imagen ${index + 1}`}
                >
                  <FaTrash />
                </button>
              )}
            </div>
          ))
        ) : (
          <p className={styles.noMediaText}>No hay imágenes en el portafolio.</p>
        )}
      </div>

      {/* Modal para ver imagen */}
      <Modal 
        isOpen={!!selectedImage} 
        onClose={() => setSelectedImage(null)}
      >
        <div className={styles.imageViewerModal}>
          <button 
            className={styles.closeViewerButton}
            onClick={() => setSelectedImage(null)}
          >
            <FaTimes />
          </button>
          {selectedImage && (
            <img 
              src={selectedImage.url} 
              alt="Vista ampliada"
              className={styles.fullImage}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </div>
      </Modal>

      {/* Modal de subida */}
      <Modal isOpen={showUploadModal} onClose={toggleUploadModal}>
        <div className={styles.modalContent}>
          <button
            className={styles.closeModalButton}
            onClick={toggleUploadModal}
            aria-label="Cerrar formulario de subida"
          >
            <FaTimes />
          </button>
          <h3 className={styles.modalTitle}>Subir Imagen al Portafolio</h3>
          <div className={styles.formLayout}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className={styles.formInput}
            />
            {imagePreview && (
              <div className={styles.imagePreview}>
                <img src={imagePreview} alt="Vista Previa" className={styles.previewImage} />
              </div>
            )}
            {mediaError && <p className={styles.errorText}>{mediaError}</p>}
          </div>
          <button
            onClick={handleUpload}
            className={styles.submitButton}
            disabled={uploading}
          >
            {uploading ? 'Subiendo...' : 'Subir Imagen'}
          </button>
        </div>
      </Modal>

      {/* Modal de confirmación de eliminación */}
      <Modal isOpen={!!confirmDeleteImage} onClose={() => setConfirmDeleteImage(null)}>
        {confirmDeleteImage && (
          <div className={styles.confirmModalContent}>
            <h3>Confirmar Eliminación</h3>
            <p>¿Estás seguro de que deseas eliminar esta imagen del portafolio?</p>
            <div className={styles.confirmButtons}>
              <button
                className={styles.confirmButton}
                onClick={() => handleDeleteImage(confirmDeleteImage)}
                disabled={isDeletingImageId === confirmDeleteImage.storagePath}
              >
                {isDeletingImageId === confirmDeleteImage.storagePath ? 'Eliminando...' : 'Sí, Eliminar'}
              </button>
              <button
                className={styles.cancelButton}
                onClick={() => setConfirmDeleteImage(null)}
              >
                Cancelar
              </button>
            </div>
          </div>
        )}
      </Modal>

      {/* Cropper Modal */}
      {imageFile && (
        <ImageCropperModal
          isOpen={showUploadModal && imagePreview !== null}
          onClose={toggleUploadModal}
          onSave={(croppedBlob) => {
            const croppedFile = new File([croppedBlob], imageFile.name, { type: imageFile.type });
            setImageFile(croppedFile);
            setImagePreview(URL.createObjectURL(croppedFile));
          }}
          imageSrc={imagePreview}
          aspect={1 / 1}
        />
      )}
    </div>
  );
};

ProfilePortfolio.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
};

export default ProfilePortfolio;