// src/components/Success.js

import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';

const Success = () => {
  const [status, setStatus] = useState('Verificando pago...');
  const location = useLocation();
  const navigate = useNavigate();
  const { user, authToken } = useContext(UserContext);

  useEffect(() => {
    const verifyPayment = async () => {
      const params = new URLSearchParams(location.search);
      const transactionId = params.get('transactionId');

      if (!transactionId) {
        setStatus('Error: Información de pago incompleta');
        return;
      }

      if (!user || !authToken) {
        setStatus('Por favor, inicia sesión para verificar la transacción.');
        return;
      }

      try {
        const response = await fetch('https://us-central1-illustra-6ca8a.cloudfunctions.net/api/verifyPayment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify({ uid: user.uid, transactionId }),
        });

        const data = await response.json();

        if (data.success) {
          setStatus('¡Pago exitoso! Tu saldo ha sido actualizado.');
          setTimeout(() => {
            navigate('/dashboard');
          }, 3000);
        } else {
          setStatus(`Error: ${data.message || 'No se pudo verificar el pago.'}`);
        }
      } catch (error) {
        console.error('Error al verificar el pago:', error);
        setStatus('Error al verificar el pago. Por favor, contacta a soporte.');
      }
    };

    verifyPayment();
  }, [location, navigate, user, authToken]);

  return (
    <div className="payment-status-container">
      <h1>Estado del Pago</h1>
      <p>{status}</p>
    </div>
  );
};

export default Success;
