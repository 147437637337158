import React, { useState } from 'react';
import { FaInstagram, FaTwitter, FaGithub, FaLinkedin, FaGlobe, FaEdit } from 'react-icons/fa';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { toast } from 'react-toastify';
import Modal from '../Shared/Modal';
import styles from './ProfileSocial.module.css';
import PropTypes from 'prop-types';

const ProfileSocial = ({ links, isOwner, userId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedLinks, setEditedLinks] = useState(links);
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    try {
      await updateDoc(doc(db, 'users', userId), {
        socialLinks: editedLinks
      });
      toast.success('Enlaces sociales actualizados');
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating social links:', error);
      toast.error('Error al actualizar enlaces sociales');
    } finally {
      setSaving(false);
    }
  };

  const SocialIcon = ({ type, link }) => {
    const icons = {
      instagram: FaInstagram,
      twitter: FaTwitter,
      github: FaGithub,
      linkedin: FaLinkedin,
      website: FaGlobe
    };
    
    const Icon = icons[type];
    
    if (!link) return null;
    
    return (
      <a 
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={`${styles.socialLink} ${styles[type]}`}
        title={type.charAt(0).toUpperCase() + type.slice(1)}
      >
        <Icon />
      </a>
    );
  };

  return (
    <div className={styles.socialContainer}>
      <div className={styles.socialLinks}>
        {Object.entries(links).map(([type, link]) => (
          <SocialIcon key={type} type={type} link={link} />
        ))}
        {isOwner && (
          <button 
            className={styles.editButton} 
            onClick={() => setIsEditing(true)}
            title="Editar enlaces sociales"
          >
            <FaEdit />
          </button>
        )}
      </div>

      {isEditing && (
        <Modal isOpen onClose={() => setIsEditing(false)}>
          <div className={styles.modalContent}>
            <h2>Editar Enlaces Sociales</h2>
            {Object.entries(editedLinks).map(([type, link]) => (
              <div key={type} className={styles.inputGroup}>
                <label htmlFor={type}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </label>
                <input
                  type="url"
                  id={type}
                  value={link}
                  onChange={(e) => setEditedLinks(prev => ({
                    ...prev,
                    [type]: e.target.value
                  }))}
                  placeholder={`URL de ${type}`}
                />
              </div>
            ))}
            <div className={styles.modalButtons}>
              <button
                className={styles.saveButton}
                onClick={handleSave}
                disabled={saving}
              >
                {saving ? 'Guardando...' : 'Guardar'}
              </button>
              <button
                className={styles.cancelButton}
                onClick={() => setIsEditing(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

ProfileSocial.propTypes = {
  links: PropTypes.object.isRequired,
  isOwner: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
};

export default ProfileSocial;