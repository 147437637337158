// src/components/CallbackPage.js

import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { toast } from 'react-toastify';

const CallbackPage = () => {
  const navigate = useNavigate();
  const { authToken } = useContext(UserContext);

  useEffect(() => {
    const processCallback = async () => {
      const query = new URLSearchParams(window.location.search);
      const code = query.get('code');
      const state = query.get('state');

      if (!code || !state) {
        toast.error('Parámetros de autenticación inválidos');
        navigate('/configuration');
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/link-mercadopago`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
              code,
              state,
              redirectUri: process.env.REACT_APP_MERCADOPAGO_REDIRECT_URI,
            }),
          }
        );

        if (!response.ok) {
          const errorData = await response.text();
          throw new Error(errorData);
        }

        toast.success('Cuenta vinculada exitosamente');
        navigate('/configuration');
      } catch (error) {
        console.error('Error al vincular cuenta:', error);
        toast.error(`Error al vincular cuenta: ${error.message}`);
        navigate('/configuration');
      }
    };

    processCallback();
  }, [navigate, authToken]);

  return (
    <div className="callback-container">
      <h2>Procesando vinculación con Mercado Pago...</h2>
      <div className="loading-spinner"></div>
    </div>
  );
};

export default CallbackPage;
