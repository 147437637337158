import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { 
  collection, 
  query, 
  onSnapshot, 
  doc,
  writeBatch,
  orderBy,
  increment,
  where,
  deleteDoc
} from 'firebase/firestore';
import { Trash2 } from 'lucide-react';
import { useTheme } from './darkMode/ThemeProvider';
import { toast } from 'react-toastify';
import '../Styles/TransactionHistory.css';

const TransactionHistory = ({ userId, isAdmin = false }) => {
  const { theme } = useTheme();
  const [transactions, setTransactions] = useState([]);
  const [visibleTransactions, setVisibleTransactions] = useState(5);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let unsubscribe;

    const setupTransactionListener = () => {
      try {
        let transactionsQuery;
        
        if (isAdmin) {
          transactionsQuery = query(
            collection(db, 'transactions'),
            orderBy('timestamp', 'desc')
          );
        } else if (userId) {
          transactionsQuery = query(
            collection(db, `users/${userId}/transactions`),
            orderBy('timestamp', 'desc')
          );
        } else {
          setLoading(false);
          return;
        }

        unsubscribe = onSnapshot(transactionsQuery, (querySnapshot) => {
          const transactionList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTransactions(transactionList);
          setLoading(false);
        }, (err) => {
          console.error('Error al obtener las transacciones:', err);
          setError('Error al cargar las transacciones');
          setLoading(false);
        });
      } catch (err) {
        console.error('Error al configurar el listener:', err);
        setError('Error al configurar el sistema de transacciones');
        setLoading(false);
      }
    };

    setupTransactionListener();
    return () => unsubscribe && unsubscribe();
  }, [userId, isAdmin]);

  const handleDeleteTransaction = async (id, amount) => {
    if (isDeleting) return;
    
    setIsDeleting(true);
    try {
      const batch = writeBatch(db);

      // Referencias necesarias
      const transactionRef = doc(db, `users/${userId}/transactions`, id);
      const userRef = doc(db, 'users', userId);
      const withdrawalRequestRef = doc(db, 'withdrawalRequests', id);

      // Eliminar la transacción del usuario
      batch.delete(transactionRef);
      
      // Devolver el balance al usuario
      batch.update(userRef, {
        balance: increment(Math.abs(amount)),
        pendingBalance: increment(-Math.abs(amount))
      });

      // Eliminar la solicitud de retiro
      batch.delete(withdrawalRequestRef);

      await batch.commit();

      // También eliminar el documento global si existe
      try {
        await deleteDoc(doc(db, 'transactions', id));
      } catch (error) {
        console.log('No se encontró transacción global para eliminar');
      }

      toast.success('Solicitud de retiro cancelada exitosamente');
    } catch (error) {
      console.error('Error al cancelar el retiro:', error);
      toast.error('Error al cancelar la solicitud');
    } finally {
      setIsDeleting(false);
    }
  };

  const formatDate = timestamp => {
    if (!timestamp) return '';
    return new Intl.DateTimeFormat('es-AR', {
      dateStyle: 'medium',
      timeStyle: 'short'
    }).format(timestamp.toDate());
  };

  const formatAmount = amount => {
    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2
    }).format(amount);
  };

  if (loading) {
    return (
      <div className="transaction-history" data-theme={theme}>
        <div className="loading">
          <div className="spinner"></div>
          <p>Cargando transacciones...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="transaction-history" data-theme={theme}>
        <div className="error">
          <p>{error}</p>
          <button onClick={() => window.location.reload()}>Reintentar</button>
        </div>
      </div>
    );
  }

  return (
    <div className="transaction-history" data-theme={theme}>
      <h2>Historial de Transacciones</h2>
      {transactions.length === 0 ? (
        <p className="no-transactions">No hay transacciones registradas</p>
      ) : (
        <div className="transactions-container">
          <ul className="transaction-list">
            {transactions.slice(0, visibleTransactions).map(tx => (
              <li key={tx.id} className={`transaction-item ${tx.status}`}>
                <div className="transaction-info">
                  <span className="transaction-type">
                    {tx.type === 'recharge' ? 'Recarga' : 'Retiro'}
                  </span>
                  <span className={`transaction-amount ${tx.amount < 0 ? 'negative' : 'positive'}`}>
                    {formatAmount(tx.amount)}
                  </span>
                  <span className="transaction-date">
                    {formatDate(tx.timestamp)}
                  </span>
                </div>
                <div className="transaction-actions">
                  <span className={`transaction-status ${tx.status}`}>
                    {tx.status === 'completed' ? 'Completado' : 'Pendiente'}
                  </span>
                  {tx.status === 'pending' && tx.type === 'withdrawal' && (
                    <button
                      className="delete-transaction-button"
                      onClick={() => handleDeleteTransaction(tx.id, tx.amount)}
                      disabled={isDeleting}
                      aria-label="Cancelar retiro"
                    >
                      <Trash2 size={18} />
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
          {visibleTransactions < transactions.length && (
            <button 
              onClick={() => setVisibleTransactions(prev => prev + 5)}
              className="load-more-btn"
            >
              Ver más
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;