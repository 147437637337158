import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTimes, FaTag, FaClock, FaDollarSign } from 'react-icons/fa';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import styles from './serviceDetailsModal.module.css';

const formatPrice = (price) => {
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2
  }).format(price);
};

const ServiceDetailsSection = ({ title, content }) => (
  <div className={styles.detailSection}>
    <h4>{title}</h4>
    <div className={styles.detailBox}>
      <p>{content}</p>
    </div>
  </div>
);

const ServiceDetailsModal = ({ service, closeModal }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!service.illustratorId) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const userRef = doc(db, 'users', service.illustratorId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserProfile(userDoc.data());
        }
      } catch (error) {
        console.error('Error al recuperar el perfil:', error);
        setError('No se pudo cargar el perfil del proveedor');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, [service.illustratorId]);

  const handleHire = (priceOption) => {
    navigate(`/service-request/${service.illustratorId}/${service.serviceID}${priceOption ? `?price=${priceOption.price}` : ''}`);
    closeModal();
  };

  const handleViewProfile = () => {
    navigate(`/profile/${service.illustratorId}`);
    closeModal();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  const getMinPrice = () => {
    if (!service.prices?.length) return '0';
    
    const validPrices = service.prices
      .filter(price => price.price && Number(price.price) > 0)
      .map(price => Number(price.price));
      
    return validPrices.length ? Math.min(...validPrices) : '0';
  };

  const hasValidPrices = service.prices?.some(price => 
    price.price && Number(price.price) > 0
  );

  if (isLoading) {
    return <div className={styles.loadingSpinner}>Cargando...</div>;
  }

  if (error) {
    return <div className={styles.errorMessage}>{error}</div>;
  }

  return (
    <div 
      className={styles.modalContent} 
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div className={styles.imageContainer}>
        <img
          src={service.imageUrl || '/placeholder.svg'}
          alt={service.title}
          className={styles.serviceImage}
        />
        <button
          onClick={closeModal}
          className={styles.closeButton}
          aria-label="Cerrar"
        >
          <FaTimes />
        </button>
      </div>

      <div className={styles.modalBody}>
        <h2 className={styles.serviceTitle}>{service.title}</h2>

        <div className={styles.providerInfo}>
          <img
            src={userProfile?.photoURL || '/user-placeholder.png'}
            alt={userProfile?.displayName || 'Usuario'}
            className={styles.providerImage}
          />
          <div className={styles.providerDetails}>
            <h3 className={styles.providerName}>
              {userProfile?.displayName || 'Proveedor'}
            </h3>
            <button 
              className={styles.viewProfileButton} 
              onClick={handleViewProfile}
            >
              Ver Perfil
            </button>
          </div>
        </div>

        <div className={styles.serviceDetails}>
          {hasValidPrices && (
            <div className={styles.detailItem}>
              <FaDollarSign className={styles.icon} />
              <span className={styles.price}>
                Desde {formatPrice(getMinPrice())}
              </span>
            </div>
          )}
          <div className={styles.detailItem}>
            <FaTag className={styles.icon} />
            <span>{service.category || 'Sin categoría'}</span>
          </div>
          <div className={styles.detailItem}>
            <FaClock className={styles.icon} />
            <span>{service.deliveryTime || 'Consultar'}</span>
          </div>
        </div>

        {service.description && (
          <ServiceDetailsSection 
            title="Descripción"
            content={service.description}
          />
        )}

        {service.terms && (
          <ServiceDetailsSection 
            title="Términos del Servicio"
            content={service.terms}
          />
        )}

        {service.specifications && (
          <ServiceDetailsSection 
            title="Especificaciones"
            content={service.specifications}
          />
        )}

        {hasValidPrices && (
          <div className={styles.pricesSection}>
            <h4>Opciones de Precio</h4>
            <div className={styles.pricesTable}>
              <div className={styles.tableHeader}>
                <div>Precio</div>
                <div>Descripción</div>
                <div>Tiempo de entrega</div>
                <div>Acciones</div>
              </div>
              {service.prices
                .filter(price => price.price && Number(price.price) > 0)
                .map((priceOption, index) => (
                  <div key={index} className={styles.tableRow}>
                    <div className={styles.priceColumn}>
                      {formatPrice(priceOption.price)}
                    </div>
                    <div className={styles.descriptionColumn}>
                      {priceOption.explanation}
                    </div>
                    <div className={styles.deliveryColumn}>
                      {priceOption.deliveryTime || 'Consultar'}
                    </div>
                    <div className={styles.actionColumn}>
                      <button 
                        onClick={() => handleHire(priceOption)} 
                        className={styles.selectButton}
                      >
                        Seleccionar
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className={styles.actionButtons}>
          <button onClick={() => handleHire()} className={styles.hireButton}>
            Contratar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsModal;