import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { 
  collection, 
  onSnapshot, 
  updateDoc, 
  doc, 
  getDoc, 
  deleteDoc,
  query,
  orderBy,
  where,
  writeBatch,
  serverTimestamp
} from 'firebase/firestore';
import '../Styles/AdminDashboard.css';
import SendNotification from './sendNotifications';
import TransactionHistory from './TransactionHistory';
import { toast } from 'react-toastify';
import axios from 'axios';

const AdminDashboard = () => {
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [processingRequest, setProcessingRequest] = useState(null);

  useEffect(() => {
    try {
      const withdrawalQuery = query(
        collection(db, 'withdrawalRequests'),
        where('status', '==', 'pending'),
        orderBy('timestamp', 'desc')
      );

      const unsubscribe = onSnapshot(withdrawalQuery, (snapshot) => {
        const requests = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setWithdrawalRequests(requests);
        setLoading(false);
      }, (err) => {
        console.error('Error al obtener solicitudes:', err);
        setError('Error al cargar las solicitudes de retiro');
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (err) {
      console.error('Error en useEffect:', err);
      setError('Error al inicializar el dashboard');
      setLoading(false);
    }
  }, []);

  const handleApprove = async (requestId) => {
    if (processingRequest) return;
    setProcessingRequest(requestId);

    try {
      const batch = writeBatch(db);
      
      // Obtener datos de la solicitud
      const requestRef = doc(db, 'withdrawalRequests', requestId);
      const requestDoc = await getDoc(requestRef);
      const requestData = requestDoc.data();

      if (!requestData) {
        throw new Error('Solicitud no encontrada');
      }

      const amountWithCommission = requestData.amount * 0.9; // 10% de comisión
      const userId = requestData.userId;

      // Referencias necesarias
      const userRef = doc(db, 'users', userId);
      const userTransactionRef = doc(db, `users/${userId}/transactions`, requestId);
      const globalTransactionRef = doc(db, 'transactions', requestId);

      try {
        // Intentar procesar el pago
        const paymentResponse = await axios.post('https://us-central1-illustra-6ca8a.cloudfunctions.net/api/approvePayment', {
          amount: amountWithCommission,
          userId: userId,
          requestId: requestId
        });

        if (paymentResponse.data.success) {
          // Actualizar todos los documentos relacionados
          batch.update(requestRef, { 
            status: 'completed',
            processedAmount: amountWithCommission,
            completedAt: serverTimestamp()
          });

          batch.update(userTransactionRef, {
            status: 'completed',
            processedAmount: amountWithCommission
          });

          batch.update(globalTransactionRef, {
            status: 'completed',
            processedAmount: amountWithCommission
          });

          batch.update(userRef, {
            pendingBalance: 0
          });

          await batch.commit();
          toast.success('Retiro procesado exitosamente');
        } else {
          throw new Error('Error en el procesamiento del pago');
        }
      } catch (error) {
        throw new Error('Error al procesar el pago: ' + error.message);
      }
    } catch (error) {
      console.error('Error al aprobar retiro:', error);
      toast.error('Error al procesar el retiro: ' + error.message);
    } finally {
      setProcessingRequest(null);
    }
  };

  const handleDeny = async (requestId) => {
    if (processingRequest) return;
    setProcessingRequest(requestId);

    try {
      const batch = writeBatch(db);
      
      const requestRef = doc(db, 'withdrawalRequests', requestId);
      const requestDoc = await getDoc(requestRef);
      const requestData = requestDoc.data();

      if (!requestData) {
        throw new Error('Solicitud no encontrada');
      }

      const userId = requestData.userId;
      
      // Referencias necesarias
      const userRef = doc(db, 'users', userId);
      const userTransactionRef = doc(db, `users/${userId}/transactions`, requestId);
      const globalTransactionRef = doc(db, 'transactions', requestId);

      // Restaurar el balance del usuario
      batch.update(userRef, {
        balance: requestData.amount,
        pendingBalance: 0
      });

      // Actualizar estado en todos los documentos
      batch.update(requestRef, { 
        status: 'denied',
        deniedAt: serverTimestamp()
      });

      batch.update(userTransactionRef, {
        status: 'denied'
      });

      batch.update(globalTransactionRef, {
        status: 'denied'
      });

      await batch.commit();
      toast.info('Solicitud de retiro denegada');
    } catch (error) {
      console.error('Error al denegar retiro:', error);
      toast.error('Error al denegar el retiro');
    } finally {
      setProcessingRequest(null);
    }
  };

  if (loading) {
    return (
      <div className="admin-dashboard">
        <div className="loading-spinner">
          <div className="spinner"></div>
          <p>Cargando dashboard administrativo...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-dashboard">
        <div className="error-message">
          <p>{error}</p>
          <button onClick={() => window.location.reload()}>Reintentar</button>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-dashboard">
      <h1>Dashboard de Administración</h1>
      <SendNotification />
      
      <section className="withdrawal-requests-section">
        <h2>Solicitudes de Retiro Pendientes</h2>
        {withdrawalRequests.length === 0 ? (
          <p className="no-requests">No hay solicitudes pendientes</p>
        ) : (
          withdrawalRequests.map(request => (
            <div key={request.id} className="withdrawal-request">
              <div className="user-info">
                <img 
                  src={request.userPhotoURL || '/default-avatar.png'} 
                  alt={request.username} 
                  className="user-photo" 
                />
                <span className="username">{request.username}</span>
                <span className="email">{request.email}</span>
              </div>
              <div className="request-details">
                <span className="amount">
                  ${(request.amount * 0.9).toLocaleString('es-AR')}
                </span>
                <span className="commission">
                  (Comisión: ${(request.amount * 0.1).toLocaleString('es-AR')})
                </span>
              </div>
              <div className="action-buttons">
                <button 
                  className="approve-btn"
                  onClick={() => handleApprove(request.id)}
                  disabled={processingRequest === request.id}
                >
                  {processingRequest === request.id ? 'Procesando...' : 'Aprobar'}
                </button>
                <button 
                  className="deny-btn"
                  onClick={() => handleDeny(request.id)}
                  disabled={processingRequest === request.id}
                >
                  Denegar
                </button>
              </div>
            </div>
          ))
        )}
      </section>

      <section className="transactions-section">
        <h2>Historial Global de Transacciones</h2>
        <TransactionHistory isAdmin={true} />
      </section>
    </div>
  );
};

export default AdminDashboard;