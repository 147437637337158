// src/components/Register.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification, onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { BlockedDomainsContext } from '../context/BlockedDomainsContext'; // Importar el contexto
import '../Styles/register.css';

const defaultProfilePic = "https://firebasestorage.googleapis.com/v0/b/illustra-6ca8a.appspot.com/o/non_profile_pic.png?alt=media&token=9ef84cb8-bae5-48cf-aed9-f80311cc2886";
const defaultBackgroundURL = "";  // URL por defecto para el fondo de perfil

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [adultContent, setAdultContent] = useState('SFW'); 
    const [isArtist, setIsArtist] = useState(false);
    const [gender, setGender] = useState(''); 
    const [customGender, setCustomGender] = useState(''); 
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);  // Estado de carga
    const navigate = useNavigate();
    const blockedDomains = useContext(BlockedDomainsContext); // Usar el contexto

    useEffect(() => {
        document.body.classList.add('register-page');
        return () => {
            document.body.classList.remove('register-page');
        };
    }, []);

    useEffect(() => {
        const checkVerification = onAuthStateChanged(auth, (user) => {
            if (user && user.emailVerified) {
                navigate('/'); // Redirige al usuario a la página principal después de la verificación
            }
        });
        return () => checkVerification();
    }, [navigate]);

    // Función para verificar si el dominio del correo está bloqueado
    const isDisposableEmail = (email) => {
        const emailParts = email.split('@');
        if (emailParts.length !== 2) return false; // Validar estructura básica
        const domain = emailParts[1].toLowerCase();
        return blockedDomains.includes(domain);
    };

    // Función para verificar si el username ya está en uso
    const isUsernameTaken = async (username) => {
        const usernameLower = username.toLowerCase();
        const q = doc(db, "users", usernameLower);
        const docSnap = await getDoc(q);
        return docSnap.exists();
    };

    const handleRegister = async (event) => {
        event.preventDefault();
        setError('');  // Limpiar errores previos

        // Validaciones básicas
        if (!acceptTerms) {
            setError('Debes aceptar los términos y condiciones para registrarte.');
            return;
        }

        if (password.length < 6) {
            setError('La contraseña debe tener al menos 6 caracteres.');
            return;
        }

        try {
            setLoading(true);  // Inicia el estado de carga

            // Verificar si el correo es desechable
            if (isDisposableEmail(email)) {
                setError('Las direcciones de correo temporales no están permitidas.');
                setLoading(false);
                return;
            }

            // Verificar si el username ya está en uso
            const usernameTaken = await isUsernameTaken(username);
            if (usernameTaken) {
                setError('El nombre de usuario ya está en uso.');
                setLoading(false);
                return;
            }

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            await sendEmailVerification(user);
            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                username,
                username_lower: username.toLowerCase(),
                email,
                profilePic: defaultProfilePic,
                backgroundURL: defaultBackgroundURL, // Añadir backgroundURL aquí
                role: "User",
                verified: false,
                adultContent,
                isArtist,
                gender: gender === 'other' ? customGender : gender,
                createdAt: new Date(),
                balance: 0, // Añadir balance aquí
                pendingBalance: 0, // Añadir pendingBalance aquí
                services: [], // Ejemplo de un campo adicional
                notifications: [], // Ejemplo de un campo adicional
            });
            console.log('Usuario registrado y correo de verificación enviado');
            navigate('/verify-email');  // Redirige a la página de verificación de correo
        } catch (error) {
            console.error('Error al registrar el usuario:', error.message);
            // Manejo de errores más específico
            if (error.code === 'auth/email-already-in-use') {
                setError('El correo electrónico ya está en uso.');
            } else if (error.code === 'auth/invalid-email') {
                setError('El correo electrónico no es válido.');
            } else if (error.code === 'auth/weak-password') {
                setError('La contraseña es demasiado débil.');
            } else {
                setError('Ocurrió un error durante el registro. Por favor, intenta de nuevo.');
            }
        } finally {
            setLoading(false);  // Finaliza el estado de carga
        }
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) { 
            setUsername(value);
        }
    };

    return (
        <div className="register-container">
            <h1>Registro</h1>
            <form onSubmit={handleRegister}>
                <input type="text" value={username} onChange={handleUsernameChange} placeholder="Username" required />
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
                <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" required />
                <div className="artist-option">
                    <label>
                        <input
                            type="radio"
                            value={true}
                            checked={isArtist === true}
                            onChange={() => setIsArtist(true)}
                        />
                        soy ilustrador/a
                    </label>
                    <label>
                        <input
                            type="radio"
                            value={false}
                            checked={isArtist === false}
                            onChange={() => setIsArtist(false)}
                        />
                        soy usuario/a
                    </label>
                </div>
                {isArtist && (
                    <div className="content-options">
                        <h2>¿Qué contenido crearás?</h2>
                        <label>
                            <input
                                type="radio"
                                value="SFW"
                                checked={adultContent === 'SFW'}
                                onChange={(e) => setAdultContent(e.target.value)}
                            />
                            SFW
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="NSFW"
                                checked={adultContent === 'NSFW'}
                                onChange={(e) => setAdultContent(e.target.value)}
                            />
                            NSFW
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="NSFW-SFW"
                                checked={adultContent === 'NSFW-SFW'}
                                onChange={(e) => setAdultContent(e.target.value)}
                            />
                            NSFW-SFW
                        </label>
                    </div>
                )}
                <div className="gender-selection">
                    <h2>Sexo / Género:</h2>
                    <label>
                        <input
                            type="radio"
                            value="male"
                            checked={gender === 'male'}
                            onChange={(e) => setGender(e.target.value)}
                        />
                        Masculino
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="female"
                            checked={gender === 'female'}
                            onChange={(e) => setGender(e.target.value)}
                        />
                        Femenino
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="other"
                            checked={gender === 'other'}
                            onChange={(e) => setGender(e.target.value)}
                        />
                        Otro
                    </label>
                    {gender === 'other' && (
                        <input
                            type="text"
                            value={customGender}
                            onChange={(e) => setCustomGender(e.target.value)}
                            placeholder="Especifique su género"
                            maxLength={14}
                            minLength={4}
                            required
                        />
                    )}
                </div>
                <div className="terms-conditions">
                    <label>
                        <input
                            type="checkbox"
                            checked={acceptTerms}
                            onChange={(e) => setAcceptTerms(e.target.checked)}
                        />
                        Acepto los <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>
                    </label>
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Registrando...' : 'Register'}
                </button>
                {error && <p className="error">{error}</p>}
            </form>
        </div>
    );
};

export default Register;
