import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import WelcomeBox from '../HomeComponents/WelcomeBox';
import LeftSideBar from '../HomeComponents/LeftSideBar';
import Feed from '../Feed/Feed';
import UsersHome from '../HomeComponents/UsersHome';
import LoadingSpinner from '../HomeComponents/LoadingSpinner';
import Footer from '../HomeComponents/Footer';
import InspectPost from '../Feed/inspectPost'; // Ajusta según corresponda
import ThemeToggle from '../darkMode/ThemeToggle';
import styles from './Home.module.css';

const Home = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [portfolioImages, setPortfolioImages] = useState([]);
  const [portfoliosLoading, setPortfoliosLoading] = useState(true);
  const [filters, setFilters] = useState({
    showNSFW: false,
    activeFilters: [],
    searchTerm: ''
  });
  const [posts, setPosts] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setLoading(true);
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        try {
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setUser({ ...currentUser, ...userDoc.data() });
          } else {
            setUser(currentUser);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setUser(currentUser);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchPortfolioImages = async () => {
      setPortfoliosLoading(true);
      try {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const allImages = [];

        usersSnapshot.forEach((userDoc) => {
          const portfolio = userDoc.data().portfolio;
          if (Array.isArray(portfolio)) {
            portfolio.forEach((imageObj) => {
              allImages.push(typeof imageObj === 'string' ? imageObj : imageObj.url);
            });
          }
        });

        setPortfolioImages(allImages);
      } catch (error) {
        console.error("Error fetching portfolio images:", error);
      }
      setPortfoliosLoading(false);
    };

    fetchPortfolioImages();
  }, []);

  const handleFilterChange = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const handleOpenPost = (postId) => {
    setSelectedPostId(postId);
  };

  const handleClosePost = () => {
    setSelectedPostId(null);
  };

  if (loading || portfoliosLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.homeContainer}>
      <Helmet>
        <title>ILLUSTRA - Conecta con artistas, contrata servicios y promociona tus ilustraciones</title>
        <meta name="description" content="Descubre ILLUSTRA, la plataforma para conectar con artistas, 
        contratar servicios y promocionar tus ilustraciones. Únete a nuestra comunidad creativa hoy." />
      </Helmet>

      <WelcomeBox portfolioImages={portfolioImages} user={user} />

      <main className={styles.homeContent}>
        <section className={styles.featuredArtistsSection}>
          <h2 className={styles.sectionTitle}>Artistas Destacados</h2>
          <UsersHome />
        </section>

        <div className={styles.mainContentWrapper}>
          <aside className={styles.leftSideBar}>
            <LeftSideBar onFilterChange={handleFilterChange} />
          </aside>
          <section className={styles.feedSection}>
            <h2 className={styles.feedTitle}>Feed de Publicaciones</h2>
            <div className={styles.feedContainer}>
              <Feed 
                filters={filters} 
                posts={posts} 
                onOpenPost={handleOpenPost}
                className={!user ? styles.blurred : ''}
              />
            </div>
          </section>
        </div>
      </main>

      <ThemeToggle />

      <Footer />

      {selectedPostId && (
        <div className={styles.modalOverlay}>
          <InspectPost
            postId={selectedPostId}
            onClose={handleClosePost}
          />
        </div>
      )}
    </div>
  );
};

export default Home;
