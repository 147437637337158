// src/components/MercadoPagoButton.js

import React, { useState, useContext, useEffect } from 'react';
import UserContext from '../../context/UserContext';
import { toast } from 'react-toastify';

const MercadoPagoButton = () => {
  const { user } = useContext(UserContext);
  const [isLinked, setIsLinked] = useState(false);

  useEffect(() => {
    if (user) {
      setIsLinked(!!user.mercadoPagoAccessToken);
    }
  }, [user]);

  const handleConnect = () => {
    if (!user?.uid) {
      toast.error('No se ha cargado la información del usuario.');
      return;
    }

    const clientId = process.env.REACT_APP_MERCADOPAGO_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_MERCADOPAGO_REDIRECT_URI;
    const authUrl = `https://auth.mercadopago.com.ar/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&state=${user.uid}`;

    window.location.href = authUrl;
  };

  const handleDisconnect = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/unlink-mercadopago`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.authToken}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response.text();
        throw new Error(`Error al desvincular: ${text}`);
      }

      setIsLinked(false);
      toast.success('Cuenta de Mercado Pago desvinculada con éxito');
    } catch (error) {
      console.error('Error al desvincular Mercado Pago:', error);
      toast.error(`Error al desvincular: ${error.message}`);
    }
  };

  return (
    <div className="mercadopago-button">
      {isLinked ? (
        <button onClick={handleDisconnect} className="disconnect-button">
          Desvincular Mercado Pago
        </button>
      ) : (
        <button onClick={handleConnect} className="connect-button">
          Conectar con Mercado Pago
        </button>
      )}
    </div>
  );
};

export default MercadoPagoButton;
