// src/PaymentsStatus/Failure.js

import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import './Failure.css';

const Failure = () => {
  const [status, setStatus] = useState('Cancelando pago...');
  const location = useLocation();
  const navigate = useNavigate();
  const { user, authToken } = useContext(UserContext);

  useEffect(() => {
    const cancelPayment = async () => {
      const params = new URLSearchParams(location.search);
      const transactionId = params.get('transactionId');

      if (!transactionId) {
        setStatus('Error: Información de pago incompleta');
        return;
      }

      if (!user || !authToken) {
        setStatus('Por favor, inicia sesión para cancelar la transacción.');
        return;
      }

      try {
        const response = await fetch('https://us-central1-illustra-6ca8a.cloudfunctions.net/api/cancelPayment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify({ transactionId }),
        });

        const data = await response.json();

        if (data.success) {
          setStatus('Pago cancelado correctamente.');
          setTimeout(() => {
            navigate('/dashboard');
          }, 3000);
        } else {
          setStatus(`Error: ${data.error || 'No se pudo cancelar el pago.'}`);
        }
      } catch (error) {
        console.error('Error al cancelar el pago:', error);
        setStatus('Error al cancelar el pago. Por favor, contacta a soporte.');
      }
    };

    cancelPayment();
  }, [location, navigate, user, authToken]);

  return (
    <div className="payment-status-container">
      <h1>Pago Cancelado</h1>
      <p>{status}</p>
      {user && authToken ? (
        <p>
          <span className="back-link" onClick={() => navigate('/dashboard')}>
            Volver
          </span>
        </p>
      ) : (
        <p>
          <span className="back-link" onClick={() => navigate('/login')}>
            Volver
          </span>
        </p>
      )}
    </div>
  );
};

export default Failure;
