// src/contexts/BlockedDomainsContext.js

import React, { createContext, useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { getDocs, collection } from "firebase/firestore";

export const BlockedDomainsContext = createContext([]);

export const BlockedDomainsProvider = ({ children }) => {
    const [blockedDomains, setBlockedDomains] = useState([]);

    useEffect(() => {
        const fetchBlockedDomains = async () => {
            try {
                const snapshot = await getDocs(collection(db, "blockedDomains"));
                const domains = snapshot.docs.map(doc => doc.data().domain.toLowerCase());
                setBlockedDomains(domains);
            } catch (error) {
                console.error("Error al obtener dominios bloqueados:", error);
            }
        };

        fetchBlockedDomains();
    }, []);

    return (
        <BlockedDomainsContext.Provider value={blockedDomains}>
            {children}
        </BlockedDomainsContext.Provider>
    );
};
