import React, { useEffect, useState, useContext } from 'react';
import { db } from '../../firebaseConfig';
import { doc, getDoc, collection, getDocs, addDoc, updateDoc, arrayRemove, arrayUnion, query, orderBy } from 'firebase/firestore';
import UserContext from '../../context/UserContext';
import { Heart, Share2, MessageCircle } from 'lucide-react';
import styles from './inspectPost.module.css';
import { ClipLoader } from 'react-spinners';

const defaultProfilePic = 'https://your-default-profile-pic-url';

export default function InspectPost({ postId, onClose }) {
  const { user } = useContext(UserContext);
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newComment, setNewComment] = useState('');
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        let postDoc = await getDoc(doc(db, 'PostsCollectionMature', postId));

        if (!postDoc.exists()) {
          postDoc = await getDoc(doc(db, 'PostsCollection', postId));
        }

        if (postDoc.exists()) {
          const postData = postDoc.data();
          const userDocRef = doc(db, 'users', postData.userID);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.exists() ? userDoc.data() : {};
          const commentsCollection = collection(db, `${postDoc.ref.path}/comments`);
          const commentsQuery = query(commentsCollection, orderBy('timestamp', 'desc'));
          const commentsSnapshot = await getDocs(commentsQuery);
          const comments = commentsSnapshot.docs.map((commentDoc) => ({
            id: commentDoc.id,
            ...commentDoc.data(),
          }));

          setPost({
            id: postDoc.id,
            ...postData,
            username: userData.username || 'Unknown User',
            userPhotoURL: userData.photoURL || defaultProfilePic,
            comments,
          });
          setLikes(postData.likes || 0);
          setIsLiked(postData.likedBy?.includes(user?.uid) || false);
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId, user]);

  const handleAddComment = async () => {
    if (newComment.trim() === '' || newComment.length > 320) return;

    try {
      const comment = {
        comment: newComment,
        timestamp: new Date(),
        user: user.username,
        userPhotoURL: user.photoURL || defaultProfilePic,
        userId: user.uid,
      };
      const postCollectionPath = post.isNSFW ? 'PostsCollectionMature' : 'PostsCollection';
      await addDoc(collection(db, `${postCollectionPath}/${postId}/comments`), comment);
      setPost((prevPost) => ({
        ...prevPost,
        comments: [{ id: new Date().toISOString(), ...comment }, ...(prevPost.comments || [])],
      }));
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleLike = async () => {
    try {
      const postRef = doc(db, post.isNSFW ? 'PostsCollectionMature' : 'PostsCollection', postId);
      if (isLiked) {
        await updateDoc(postRef, {
          likes: likes - 1,
          likedBy: arrayRemove(user.uid)
        });
        setLikes(likes - 1);
      } else {
        await updateDoc(postRef, {
          likes: likes + 1,
          likedBy: arrayUnion(user.uid)
        });
        setLikes(likes + 1);
      }
      setIsLiked(!isLiked);
    } catch (error) {
      console.error('Error updating like:', error);
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <ClipLoader color="#7c3aed" size={60} />
      </div>
    );
  }

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        {post && (
          <div className={styles.postContainer}>
            <div className={styles.postHeader}>
              <img src={post.userPhotoURL} alt={post.username} className={styles.userAvatar} />
              <div className={styles.postInfo}>
                <h2 className={styles.postTitle}>{post.title}</h2>
                <p className={styles.postAuthor}>by {post.username}</p>
              </div>
            </div>
            <p className={styles.postDescription}>{post.description}</p>
            {post.imageUrl && (
              <img src={post.imageUrl} alt={post.title} className={styles.postImage} />
            )}
            <div className={styles.postActions}>
              <button onClick={handleLike} className={styles.actionButton}>
                <Heart fill={isLiked ? "#ff0000" : "none"} /> {likes}
              </button>
              <button className={styles.actionButton}>
                <MessageCircle /> {post.comments?.length || 0}
              </button>
              <button className={styles.actionButton}>
                <Share2 />
              </button>
            </div>
            <div className={styles.commentSection}>
              <h3>Comments</h3>
              <form onSubmit={(e) => { e.preventDefault(); handleAddComment(); }} className={styles.commentForm}>
                <input
                  type="text"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Add a comment..."
                  className={styles.commentInput}
                  maxLength={320}
                />
                <button type="submit" className={styles.commentSubmit} disabled={newComment.trim() === ''}>
                  Post
                </button>
              </form>
              {post.comments?.map((comment) => (
                <div key={comment.id} className={styles.comment}>
                  <img src={comment.userPhotoURL} alt={comment.user} className={styles.commentAvatar} />
                  <div className={styles.commentContent}>
                    <p className={styles.commentUser}>{comment.user}</p>
                    <p className={styles.commentText}>{comment.comment}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
