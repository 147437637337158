// src/components/TermsAndConditions.js

import React from 'react';
import '../Styles/terms.css';

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1>Términos y Condiciones</h1>
            
            <h2>Bienvenido a Illustra</h2>
            <p>Este sitio web es operado por illustra.com. En todo el sitio, los términos “nosotros”, “nos” y “nuestro” se refieren a Illustra. Illustra ofrece este sitio web, incluyendo toda la información, herramientas y servicios disponibles para ti.</p>
            <p>Al usar Illustra aceptas estos términos y condiciones, incluyendo la política de privacidad y la guía de contenido.</p>
            
            <h2>Tu cuenta</h2>
            <p>Al momento de crear tu cuenta debes proporcionar información verídica y, si esta cambia, debes actualizarla. Debes tener al menos 14 años para usar Illustra y 18 para recibir dinero. Si eres menor y quieres donar o recibir, necesitarás la autorización de tu tutor.</p>
            
            <h2>Conducta</h2>
            <p>Eres responsable por toda la actividad en tu cuenta. Si incumples alguna de nuestras políticas, estamos en nuestro derecho de terminar completamente tu cuenta. No hagas nada ilegal ni abusivo contra otros o que abuse de nuestro sitio de alguna forma. Podremos revisar la actividad que realices en campañas, comisiones o con otros servicios de Illustra para asegurarnos de que se cumpla el buen uso de la página.</p>
            
            <h2>Todo sobre ser un creador</h2>
            <h3>Creadores</h3>
            <p>Eres un creador al momento que te registras para generar ingresos. Al unirte, formas parte de nuestra comunidad, puedes usar herramientas que te proporcionamos, mostrar tus creaciones, ofrecer servicios y recibir donaciones de tu audiencia.</p>
            <p>Ofrecemos a tu público diferentes formas de interactuar contigo y ser agentes activos en la creación de tu contenido; tú decidirás cuáles herramientas usarás para conectarte con ellos.</p>
            <p>Como creador, tienes el derecho de contactar al equipo detrás de Illustra para dejar tu feedback, reportar errores y más. Puedes hacerlo en nuestras redes sociales o enviando un correo a illustra.contacto@gmail.com.</p>
            
            <h3>Pagos</h3>
            <p>Como creador puedes recibir pagos de forma local o internacionalmente. Sin embargo, si detectamos que tu cuenta incumple con nuestros términos y condiciones, nos reservamos el derecho de retener o bloquear el dinero que recibas hasta verificar que todo esté en orden. Para proteger a los patrocinadores que donen dinero, podremos bloquear también completamente tu cuenta o método de pago si detectamos un uso fraudulento de la página.</p>
            <p>Por motivos de seguridad, si detectamos actividades sospechosas o por aviso de nuestro procesador de pago, es posible que rechacemos tu solicitud de retiro. Esto iniciará una investigación que puede tomar hasta 180 días. Durante este tiempo, te pediremos información para corroborar el origen del dinero recibido en Illustra. Esta información puede incluir:</p>
            <ul>
                <li>Documentos de identificación</li>
                <li>Foto de tu rostro junto con tu documento de identificación</li>
                <li>Videos, imágenes o enlaces que demuestren tus actividades y cómo recibiste el dinero</li>
            </ul>
            <p>Si no recibimos respuesta tuya en 30 días, procederemos a cerrar tu cuenta.</p>
            
            <h3>Retiro de dinero</h3>
            <p>Illustra te permite retirar tu dinero transfiriéndolo a tu cuenta bancaria. Para ello, debes contar con el monto mínimo indicado en la página, dependiendo de tu país. Cumplido el mínimo, podrás solicitar tu transferencia, lo que dará inicio al proceso de retiro.</p>
            <p>Todas tus transacciones aparecerán inicialmente como "en camino", lo que significa que llegarán a tu cuenta en un máximo de 7 días calendario. Una vez completado el proceso, el retiro se contará como completado y el dinero será transferido desde tu billetera a tu cuenta bancaria. El retiro podrá ser cancelado por solicitud del creador antes de ser aceptado, o por los motivos de seguridad indicados en la sección "Pagos".</p>
            
            <h3>Comisión</h3>
            <p>En Illustra, aplicamos un <strong>17% de recargo en cada depósito de dinero</strong>. Además, existe una comisión asociada al procesamiento del pago cuyo monto es variable dependiendo del método de pago escogido por el patrocinador.</p>
            <p><strong>El comisionador no paga comisiones extras.</strong></p>
            
            <h3>Restricciones</h3>
            <p>Para revisar las restricciones de contenido, consulta nuestra guía de contenido.</p>
            
            <h2>Todo sobre un patrocinador</h2>
            <p>Un patrocinador puede donar al creador que quiera y la cantidad que estime conveniente, a cambio de recibir beneficios, como interacciones o contenido exclusivo.</p>
            <p>El patrocinador es responsable de que su donación sea efectuada correctamente. Si se equivoca, no nos hacemos responsables, a menos que sea un error de nuestro sitio o en casos excepcionales. Para cualquier duda respecto a esto, pueden enviar un correo a illustra.contacto@gmail.com.</p>
            
            <h3>¿Qué debe hacer un patrocinador?</h3>
            <p>Además de donar, suscribirte y comprar, estamos constantemente revisando reportes y problemas. Si ves cualquier tipo de contenido que no cumpla nuestras guías, deberás reportarlo inmediatamente para que tomemos acción. En el caso de que intencionalmente estás aportando contenido fraudulento o que no siga nuestras guías, estamos en nuestro derecho de cerrar la cuenta de un patrocinador o creador.</p>
            
            <h2>Sobre reembolsos</h2>
            <p>Illustra es una plataforma de comercio electrónico entre creadores y patrocinadores. A través de la plataforma se exhiben productos de terceros ajenos a Illustra, quienes serán los únicos y exclusivos responsables de dar cumplimiento a las obligaciones legales y tributarias que el patrocinador le pague como contraprestación por el servicio contratado. Es responsabilidad de cada patrocinador verificar lo que compra, dona o suscribe. Si el creador incumple con una entrega de beneficio, el patrocinador deberá comunicarse directamente con éste para solucionar el problema, sin perjuicio de dar aviso al equipo de Illustra para que revise la cuenta y, en caso de reunirse antecedentes que den cuenta de un incumplimiento del servicio contratado, restringirla o proceder a retener los pagos. Illustra no será responsable en caso de que los creadores incurran en incumplimientos y no realizará ninguna clase de reembolsos, ni a creadores ni a patrocinadores.</p>
            
            <h2>Borrar cuenta</h2>
            <p>En el caso de que como creador o patrocinador desees borrar tu cuenta, debes enviar un correo a illustra.contacto@gmail.com. Opcionalmente, si tienes un motivo que nos puedas explicar, lo agradeceremos como un feedback valioso.</p>
            
            <h2>10. Derechos sobre contenido</h2>
            <p><strong>Propiedad del contenido:</strong> El contenido que creas y subes a Illustra es únicamente tuyo. Illustra puede solicitarte permiso para usar tu contenido, pero nadie más puede utilizar tu creación sin tu consentimiento.</p>
            
            <h3>Prohibida la IA</h3>
            <p>Está prohibido subir o utilizar contenido generado por inteligencia artificial en Illustra. Cualquier violación de esta política puede resultar en la eliminación de tu contenido y la terminación de tu cuenta.</p>
            
            <h2>Tu contenido</h2>
            <p>No puedes publicar creaciones que infrinjan la propiedad intelectual o los derechos de propiedad de otros. Los patrocinadores no pueden utilizar las creaciones publicadas por los creadores de ninguna manera que no haya sido autorizada por estos últimos.</p>
            <p>Tenemos el derecho de revisar y remover cualquier contenido que publiques en nuestra plataforma, si en nuestra opinión, es necesario para proteger nuestro servicio.</p>
            
            <h2>Indemnización</h2>
            <p>Aceptas indemnizar, defender y mantener indemne a Illustra y nuestras matrices, subsidiarias, afiliados, socios, funcionarios, directores, agentes, contratistas, concesionarios, proveedores de servicios, subcontratistas, proveedores, internos y empleados, de cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, hechos por cualquier tercero a causa o como resultado de tu incumplimiento de las Condiciones de Servicio o de los documentos que incorporan como referencia, o la violación de cualquier ley o de los derechos de un tercero.</p>
            
            <h2>Divisibilidad</h2>
            <p>En el caso de que se determine que cualquier disposición de estas condiciones de servicio sea ilegal, nula o inejecutable, dicha disposición será, no obstante, efectiva a obtener la máxima medida permitida por la ley aplicable, y la parte no exigible se considerará separada de estos Términos de Servicio. Dicha determinación no afectará la validez de aplicabilidad de las demás disposiciones restantes.</p>
            
            <h2>Enlaces de terceros</h2>
            <p>Cierto contenido, productos y servicios disponibles vía nuestro Servicio puede incluir material de terceros.</p>
            <p>Enlaces de terceros en este sitio pueden redireccionar a sitios web de terceros que no están afiliadas con nosotros. No nos responsabilizamos de examinar o evaluar el contenido o exactitud y no garantizamos ni tendremos ninguna obligación o responsabilidad por cualquier material de terceros o sitios web, o de cualquier material, productos o servicios de terceros.</p>
            <p>No nos hacemos responsables de cualquier daño o daños relacionados con la adquisición o utilización de bienes, servicios, recursos, contenidos, o cualquier otra transacción realizadas en conexión con sitios web de terceros. Por favor revisa cuidadosamente las políticas y prácticas de terceros y asegúrate de entenderlas antes de participar en cualquier transacción. Quejas, reclamos, inquietudes o preguntas con respecto a productos de terceros deben ser dirigidas a la tercera parte.</p>
            <p>Si encuentras un perfil que lleva a sitios de terceros maliciosos, por favor avísanos a illustra.contacto@gmail.com para revisar si incumple con nuestras normas.</p>
            
            <h2>Herramientas opcionales</h2>
            <p>Es posible que te proporcionemos acceso a herramientas de terceros a los cuales no monitoreamos y sobre los que no tenemos control ni entrada.</p>
            <p>Reconoces y aceptas que proporcionamos acceso a este tipo de herramientas "tal cual" y "según disponibilidad" sin garantías, representaciones o condiciones de ningún tipo y sin ningún respaldo. No tendremos responsabilidad alguna derivada de o relacionada con tu uso de herramientas proporcionadas por terceros.</p>
            <p>Cualquier uso que hagas de las herramientas opcionales que se ofrecen a través del sitio bajo tu propio riesgo y discreción y debes asegurarte de estar familiarizado y aprobar los términos bajo los cuales estas herramientas son proporcionadas por el o los proveedores de terceros.</p>
            <p>También es posible que, en el futuro, te ofrezcamos nuevos servicios y/o características a través del sitio web (incluyendo el lanzamiento de nuevas herramientas y recursos). Estas nuevas características y/o servicios también estarán sujetos a estos Términos de Servicio.</p>
            
            <h2>No somos perfectos, pero damos lo mejor</h2>
            <p>En Illustra hay un equipo de trabajo dedicado y que día a día se esfuerza para entregar una página funcional y que cumpla con todas tus necesidades, pero a veces hay errores y estamos trabajando constantemente para arreglarlos. Si encuentras algún bug, glitch, error o cualquier otra cosa relacionada con mal funcionamiento, por favor infórmalo a illustra.contacto@gmail.com.</p>
            
            <h2>¿Tienes algún problema?</h2>
            <p>Cualquier inconveniente, duda o solución que necesites puede ser contactada directamente con nosotros a través de illustra.contacto@gmail.com. Estamos continuamente pendientes para responder cualquier duda y tenemos toda la disposición para ayudarte directamente.</p>
            
            <h2>Cambios en los Términos de Servicio</h2>
            <p>Puedes revisar la versión más actualizada de los Términos de Servicio en cualquier momento en esta página.</p>
            <p>Nos reservamos el derecho, a nuestra sola discreción, de actualizar, modificar o reemplazar cualquier parte de estas Condiciones del servicio mediante la publicación de las actualizaciones y los cambios en nuestro sitio web. Es tu responsabilidad revisar nuestro sitio web periódicamente para verificar los cambios. El uso continuo de o el acceso a nuestro sitio Web o el Servicio después de la publicación de cualquier cambio en estas Condiciones de servicio implica la aceptación de dichos cambios.</p>
        </div>
    );
};

export default TermsAndConditions;
