// src/components/HomeComponents/WelcomeBox.jsx

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import {
  FaArrowRight,
  FaUserPlus,
  FaSignInAlt,
  FaPalette,
  FaUsers,
  FaBriefcase,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  limit,
} from 'firebase/firestore';
import 'swiper/css';
import 'swiper/css/effect-fade';
import styles from './WelcomeBox.module.css';

// Este será el placeholder en caso de no haber imágenes
const PLACEHOLDER_IMAGE =
  'https://via.placeholder.com/800x400?text=No+hay+im%C3%A1genes';

const WelcomeBox = ({ portfolioImages = [], user }) => {
  const navigate = useNavigate();
  const [randomImages, setRandomImages] = useState([]);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  // Función para obtener imágenes directamente de Firestore
  const fetchRandomImages = async () => {
    try {
      const db = getFirestore(); // Inicializa Firestore
      const q = query(collection(db, 'portfolio'), limit(5)); // Límite de 5 imágenes
      const querySnapshot = await getDocs(q);

      // Extrae las URLs de las imágenes desde Firestore
      const images = querySnapshot.docs.map((doc) => doc.data().imageUrl);
      setRandomImages(images);
    } catch (err) {
      console.error('Error fetching random images:', err);
      setError('No se pudieron cargar las imágenes.');
      setRandomImages([]);
    }
  };

  useEffect(() => {
    if (portfolioImages.length === 0) {
      fetchRandomImages(); // Llama a Firestore si no hay imágenes de portafolio disponibles
    }

    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  }, [portfolioImages]);

  const displayImages = useMemo(() => {
    const images = portfolioImages.length > 0 ? portfolioImages : randomImages;
    return images.filter((image) => image); // Filtra imágenes válidas
  }, [portfolioImages, randomImages]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div
      className={`${styles.welcomeBoxContainer} ${
        isVisible ? styles.visible : ''
      }`}
    >
      <div className={styles.welcomeBox}>
        <Swiper
          modules={[Autoplay, EffectFade]}
          spaceBetween={0}
          slidesPerView={1}
          effect="fade"
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          loop={true} // Añadir loop para que sea cíclico
          className={styles.welcomeSwiper}
          aria-label="Galería de imágenes de portafolio"
        >
          {displayImages.length > 0 ? (
            displayImages.map((image, index) => (
              <SwiperSlide key={index}>
                <div className={styles.slideContent}>
                  <div
                    className={styles.slideImageWrapper}
                    style={{ backgroundImage: `url(${image})` }}
                  />
                  <div className={styles.slideOverlay} />
                </div>
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <div className={styles.slideContent}>
                <div
                  className={styles.slideImageWrapper}
                  style={{ backgroundImage: `url(${PLACEHOLDER_IMAGE})` }}
                />
                <div className={styles.slideOverlay} />
                <div className={styles.slidePlaceholder}>
                  No hay imágenes disponibles
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <div className={styles.welcomeContent}>
          <h1 className={styles.welcomeTitle}>
            {user
              ? `¡Bienvenido de nuevo, ${user.username}!`
              : '¡Bienvenido a Illustra!'}
          </h1>
          <p className={styles.welcomeDescription}>
            La plataforma para artistas e ilustradores. Conecta, contrata y
            promociona tu talento.
          </p>
          <ul className={styles.featuresList}>
            <li>
              <FaPalette className={styles.featureIcon} />
              Crea tu portafolio digital
            </li>
            <li>
              <FaUsers className={styles.featureIcon} />
              Conecta con otros artistas
            </li>
            <li>
              <FaBriefcase className={styles.featureIcon} />
              Encuentra oportunidades de trabajo
            </li>
          </ul>
          <div className={styles.welcomeButtons}>
            <button
              onClick={() =>
                handleNavigate(user ? '/exploreServices' : '/register')
              }
              className={`${styles.ctaButton} ${styles.primaryButton}`}
            >
              {user ? (
                <>
                  Explorar
                  <FaArrowRight className={styles.buttonIcon} />
                </>
              ) : (
                <>
                  Registrarse
                  <FaUserPlus className={styles.buttonIcon} />
                </>
              )}
            </button>
            <button
              onClick={() =>
                handleNavigate(user ? '/explore-posts' : '/login')
              }
              className={`${styles.ctaButton} ${styles.secondaryButton}`}
            >
              {user ? (
                <>
                  Feed
                  <FaArrowRight className={styles.buttonIcon} />
                </>
              ) : (
                <>
                  Iniciar Sesión
                  <FaSignInAlt className={styles.buttonIcon} />
                </>
              )}
            </button>
          </div>
          {error && <p className={styles.errorMessage}>{error}</p>}
        </div>
      </div>
    </div>
  );
};

WelcomeBox.propTypes = {
  portfolioImages: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }),
};

export default WelcomeBox;
