import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs, query, limit, where } from 'firebase/firestore';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import styles from './UsersHome.module.css';

const defaultProfilePic = "https://firebasestorage.googleapis.com/v0/b/illustra-6ca8a.appspot.com/o/non_profile_pic.png?alt=media&token=9ef84cb8-bae5-48cf-aed9-f80311cc2886";
const excludedProfilePic = "https://firebasestorage.googleapis.com/v0/b/illustra-6ca8a.appspot.com/o/default_pic.png?alt=media&token=7e02672b-6c59-4018-88bb-667c99007af4";

const UserHome = () => {
  const [featuredUsers, setFeaturedUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeaturedUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const featuredUsersQuery = query(
          usersCollection,
          where('photoURL', '!=', null),
          limit(30)
        );
        const usersSnapshot = await getDocs(featuredUsersQuery);
        const usersList = usersSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => user.photoURL !== excludedProfilePic); // Filtra los usuarios con la foto predeterminada

        setFeaturedUsers(usersList);
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
    };

    fetchFeaturedUsers();
  }, []);

  if (featuredUsers.length === 0) {
    return <p className={styles.noUsers}>No hay usuarios destacados disponibles.</p>;
  }

  return (
    <div className={styles.userHomeContainer}>
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        pagination={{ clickable: true, dynamicBullets: true }}
        navigation={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        breakpoints={{
          640: { slidesPerView: 2, spaceBetween: 20 },
          768: { slidesPerView: 3, spaceBetween: 30 },
          1024: { slidesPerView: 4, spaceBetween: 30 },
        }}
        loop={featuredUsers.length > 4}
        className={styles.usersSwiper}
        modules={[Pagination, Autoplay, Navigation]}
      >
        {featuredUsers.map((featuredUser) => (
          <SwiperSlide key={featuredUser.id}>
            <div
              className={styles.featuredUserCard}
              onClick={() => navigate(`/profile/${featuredUser.id}`)}
            >
              <div className={styles.portfolioImageContainer}>
                {featuredUser.portfolio && featuredUser.portfolio.length > 0 ? (
                  <img
                    src={featuredUser.portfolio[0]?.url}
                    alt={`Portfolio de ${featuredUser.username}`}
                    className={styles.portfolioImage}
                  />
                ) : (
                  <div
                    className={styles.defaultProfileImage}
                    style={{ backgroundImage: `url(${featuredUser.photoURL || defaultProfilePic})` }}
                  />
                )}
                <div className={styles.userOverlay}>
                  <img
                    src={featuredUser.photoURL || defaultProfilePic}
                    alt={featuredUser.username}
                    className={styles.userPhoto}
                  />
                  <div className={styles.userInfo}>
                    <h4 className={styles.username}>{featuredUser.username}</h4>
                    <p className={styles.userBio}>
                      {featuredUser.bio
                        ? (featuredUser.bio.length > 50
                          ? `${featuredUser.bio.substring(0, 50)}...`
                          : featuredUser.bio)
                        : 'Este usuario no tiene una biografía.'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default UserHome;
