import React, { useContext, useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateDoc, doc } from 'firebase/firestore';
import { storage, db } from '../../firebaseConfig';
import UserContext from '../../context/UserContext';
import ImageCropperModal from './ImageCropperModal';
import styles from './ProfilePicture.module.css';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { FaCamera } from 'react-icons/fa';
import { toast } from 'react-toastify';

const ProfilePicture = ({ photoURL, isOwner, setPhotoURL, setError }) => {
    const { user } = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [uploading, setUploading] = useState(false);

    const handleProfilePicChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!file.type.startsWith('image/')) {
                setError('Por favor selecciona una imagen válida');
                toast.error('El archivo seleccionado no es una imagen válida');
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImageSrc(reader.result);
                setShowModal(true);
            };
        }
    };

    const handleSaveCroppedImage = async (croppedFile) => {
        if (croppedFile && user) {
            setUploading(true);
            try {
                const uniqueFileName = `profile_${uuidv4()}.jpg`;
                const profilePicRef = ref(storage, `profilePics/${user.uid}/${uniqueFileName}`);
                await uploadBytes(profilePicRef, croppedFile);
                const newPhotoURL = await getDownloadURL(profilePicRef);
                
                await updateDoc(doc(db, 'users', user.uid), { 
                    photoURL: newPhotoURL 
                });
                
                setPhotoURL(newPhotoURL);
                toast.success('Foto de perfil actualizada exitosamente');
            } catch (error) {
                console.error("Error al subir la foto de perfil:", error);
                setError('Error al actualizar la foto de perfil');
                toast.error('Error al actualizar la foto de perfil');
            } finally {
                setUploading(false);
                setShowModal(false);
            }
        }
    };

    return (
        <div className={styles.profilePicContainer}>
            <div className={styles.profilePicWrapper}>
                <img 
                    src={photoURL || '/default-profile.png'} 
                    alt="Foto de perfil" 
                    className={styles.profilePic}
                />
                
                {isOwner && (
                    <div className={styles.editOverlay}>
                        <label htmlFor="profilePicInput" className={styles.editButton}>
                            <FaCamera />
                        </label>
                        <input
                            type="file"
                            id="profilePicInput"
                            accept="image/*"
                            onChange={handleProfilePicChange}
                            className={styles.hiddenInput}
                            disabled={uploading}
                        />
                    </div>
                )}
            </div>

            {showModal && (
                <ImageCropperModal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    onSave={handleSaveCroppedImage}
                    imageSrc={imageSrc}
                    aspect={1}
                    loading={uploading}
                />
            )}
        </div>
    );
};

ProfilePicture.propTypes = {
    photoURL: PropTypes.string,
    isOwner: PropTypes.bool.isRequired,
    setPhotoURL: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
};

export default ProfilePicture;