// ProfileBio.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaEdit } from 'react-icons/fa';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import Modal from '../Shared/Modal';
import styles from './ProfileBio.module.css';

const MAX_LINE_BREAKS = 20;

const ProfileBio = ({ user, isOwner, updateBio }) => {
    const { bio, id } = user;
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editedBio, setEditedBio] = useState(bio);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState('');

    const handleBioChange = (e) => {
        const newValue = e.target.value;
        // Contar saltos de línea
        const lineBreaks = (newValue.match(/\n/g) || []).length;
        
        if (lineBreaks > MAX_LINE_BREAKS) {
            // Si excede el límite, no actualizar el valor
            setError(`Máximo ${MAX_LINE_BREAKS} saltos de línea permitidos`);
            return;
        }
        
        setError('');
        setEditedBio(newValue);
    };

    const handleSaveBio = async () => {
        if (!editedBio.trim()) {
            setError('La biografía no puede estar vacía');
            return;
        }

        // Verificar saltos de línea antes de guardar
        const lineBreaks = (editedBio.match(/\n/g) || []).length;
        if (lineBreaks > MAX_LINE_BREAKS) {
            setError(`Máximo ${MAX_LINE_BREAKS} saltos de línea permitidos`);
            return;
        }

        setSaving(true);
        setError('');

        try {
            const userRef = doc(db, 'users', id);
            await updateDoc(userRef, { bio: editedBio.trim() });
            updateBio(editedBio.trim());
            setIsEditModalOpen(false);
        } catch (err) {
            console.error('Error actualizando la biografía:', err);
            setError('No se pudo actualizar la biografía. Inténtalo de nuevo.');
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className={styles.bioSection}>
            <div className={styles.bioContainer}>
                {bio ? (
                    <p className={styles.bio}>{bio}</p>
                ) : (
                    <p className={styles.emptyBio}>Sin biografía</p>
                )}
                {isOwner && (
                    <button
                        className={styles.editButton}
                        onClick={() => setIsEditModalOpen(true)}
                        aria-label="Editar Biografía"
                    >
                        <FaEdit />
                    </button>
                )}
            </div>

            {isEditModalOpen && (
                <Modal isOpen={true} onClose={() => {
                    setIsEditModalOpen(false);
                    setEditedBio(bio);
                    setError('');
                }}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.modalTitle}>Editar Biografía</h2>
                        <textarea
                            value={editedBio}
                            onChange={handleBioChange}
                            className={styles.bioTextarea}
                            placeholder="Escribe tu biografía aquí..."
                            maxLength={500}
                            rows="5"
                        />
                        <div className={styles.bioInfo}>
                            <span className={styles.charCount}>
                                {editedBio.length}/500 caracteres
                            </span>
                            <span className={styles.lineBreakCount}>
                                {(editedBio.match(/\n/g) || []).length}/{MAX_LINE_BREAKS} saltos de línea
                            </span>
                        </div>
                        {error && <p className={styles.error}>{error}</p>}
                        <div className={styles.modalButtons}>
                            <button 
                                onClick={handleSaveBio} 
                                className={styles.saveButton} 
                                disabled={saving || !editedBio.trim()}
                            >
                                {saving ? 'Guardando...' : 'Guardar'}
                            </button>
                            <button 
                                onClick={() => {
                                    setIsEditModalOpen(false);
                                    setEditedBio(bio);
                                    setError('');
                                }} 
                                className={styles.cancelButton}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

ProfileBio.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        bio: PropTypes.string.isRequired,
    }).isRequired,
    isOwner: PropTypes.bool.isRequired,
    updateBio: PropTypes.func.isRequired,
};

export default ProfileBio;